import { ReactElement } from "react";
import { Flex } from "@chakra-ui/react";
import { IoCheckmarkSharp } from "react-icons/io5";

type Props = {
  color: string;
  label: string;
  size: number;
  isSelected?: boolean;
  onClick?: () => void;
};

export function ColorSwatchButton(props: Props): ReactElement {
  return (
    <Flex
      as="button"
      type="button"
      aria-label={props.label}
      backgroundColor={props.color}
      width={props.size}
      height={props.size}
      rounded="full"
      onClick={props.onClick}
      alignItems="center"
      justifyContent="center"
      sx={{ "@media (hover: hover)": { "&:hover": { opacity: 0.6 } } }}
    >
      {props.isSelected && (
        <IoCheckmarkSharp size={props.size * 2.5} color="white" />
      )}
    </Flex>
  );
}
