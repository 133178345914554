import { Fragment, ReactElement } from "react";
import { Box, Center, Flex, Text, VStack } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { ResultDashboardData, ResultMainParam } from "../../types";

export function MainParamBox(props: {
  mainParam: ResultMainParam;
}): ReactElement {
  const { t } = useTranslation();
  return (
    <Box
      bg="white"
      width="85%"
      maxWidth={props.mainParam.maxWidth || "120px"}
      border={props.mainParam.border}
      borderRadius="10px"
      textAlign="center"
    >
      <Text fontSize="18px" fontWeight="bold" marginTop="0.2em">
        {t(props.mainParam.title)}
      </Text>
      {props.mainParam.subtitle && (
        <Text fontSize="12px" marginTop="0.2em">
          {t(props.mainParam.subtitle)}
        </Text>
      )}
      <Text fontSize="28px" marginTop="-0.1em">
        {props.mainParam.value !== undefined && props.mainParam.value !== -100
          ? props.mainParam.value
          : "Error"}
      </Text>
    </Box>
  );
}

export function DashboardBox(props: {
  result: ResultDashboardData;
}): ReactElement {
  const { t } = useTranslation();
  return (
    <Flex
      color="text.main_text_lv1"
      bg="common.base"
      minHeight="120px"
      fontSize="16px"
      marginTop="5%"
      borderRadius="10px"
      borderWidth="2px"
      borderColor="primary.theme_lv2"
    >
      <Center width="50%">
        <MainParamBox mainParam={props.result.mainParam} />
      </Center>
      <VStack spacing={2} align="stretch" width="50%" justifyContent="center">
        {props.result.subParams.map((subParam, subParamIndex) => {
          return (
            <Flex marginTop="0%" key={subParamIndex}>
              <Box
                fontSize="14px"
                bg="#EEEEEE"
                marginLeft="0%"
                width="50%"
                minWidth="80px"
                borderRadius="5px"
                textAlign="center"
              >
                {t(subParam.title)}
              </Box>
              <Box marginLeft="5%" width="50%" fontSize="14px" textAlign="left">
                {/* NOTE: colorにredがセットされていた場合は赤字で表示し、xx(yy)形式の場合はxxのみ赤字 */}
                {/* FIXME: 暫定的な対応の為、後で実装を見直す */}
                {subParam.value !== undefined ? (
                  typeof subParam.value === "string" &&
                  subParam.color === "red" ? (
                    <>
                      {subParam.value.split(", ").map((item, index, array) => {
                        const match = item.match(/^\d+/);
                        return (
                          <Fragment key={index}>
                            <span style={{ color: "red" }}>
                              {match ? match[0] : ""}
                            </span>
                            {item.replace(/^\d+/, "")}
                            {index !== array.length - 1 && ", "}
                          </Fragment>
                        );
                      })}
                    </>
                  ) : (
                    subParam.value
                  )
                ) : (
                  "Error"
                )}
              </Box>
            </Flex>
          );
        })}
      </VStack>
    </Flex>
  );
}
