import { useToast, AlertStatus } from "@chakra-ui/react";
import { useCallback } from "react";
import axios from "axios";
import { AppApiError, isObject, isValidAppApiError } from "../types";
import { useTranslation } from "react-i18next";
import { i18n } from "i18next";

function getApiErrorMessage(
  error: AppApiError,
  fallbackMessage: string,
  i18n: i18n
): string {
  let message: string;
  if (isObject(error.detail)) {
    const tKey = `ApiError.${error.detail.type}`;
    message = i18n.exists(tKey)
      ? i18n.t(tKey)
      : error.detail.msg || fallbackMessage;
  } else if (Array.isArray(error.detail)) {
    const firstDetail = error.detail[0];
    if (firstDetail && isObject(firstDetail)) {
      message = firstDetail.msg || fallbackMessage;
    } else if (typeof firstDetail === "string") {
      message = firstDetail;
    } else {
      message = fallbackMessage;
    }
  } else {
    message = error.detail;
  }
  return message;
}

type ErrorToast = (e: unknown, toastStatus?: AlertStatus) => void;

export function useToastMessage(): ErrorToast {
  const { i18n } = useTranslation();
  const toast = useToast();
  return useCallback(
    (err: unknown, toastStatus = "error") => {
      let title: string;
      let message: string | undefined;
      if (axios.isAxiosError(err)) {
        // title
        const status = err.response?.status;
        if (status !== undefined) {
          const tKey = `ApiError.${status}`;
          const statusText = i18n.exists(tKey) ? i18n.t(tKey) : err.code;
          title = `${status}: ${statusText}`;
        } else {
          title = `${err.code}`;
        }

        // message
        const responseData = err.response?.data;
        if (isValidAppApiError(responseData)) {
          message = getApiErrorMessage(responseData, err.message, i18n);
        } else {
          message = err.message;
        }
      } else if (err instanceof Error) {
        title = err.name;
        message = err.message;
      } else if (typeof err === "string") {
        title = err;
      } else {
        title = "Internal Error";
      }

      toast({
        status: toastStatus,
        containerStyle: { whiteSpace: "pre-wrap" },
        isClosable: true,
        duration: 5000,
        title: title,
        description: message,
      });
    },
    [i18n, toast]
  );
}
