import {
  BaseAnalysisEngine,
  BaseAnalysisResult,
  isOfType,
} from "./baseAnalysisEngine";
import { ReactElement } from "react";
import { TFunction } from "react-i18next";
import { ResultDashboard } from "../components/molecules/ResultDashboard";
import { ResultCo1Demo } from "../components/molecules/ResultCo1Demo";
import {
  AnalysisEngineType,
  ApiEngineType,
  MeanResult,
  ResultDashboardData,
  ResultMainParam,
  ResultSubParam,
} from "../types";
import { appAxios } from "../utils/appAxios";

export type AnalysisResult = BaseAnalysisResult & {
  co1_result: string;
  co1_score: number;
  co1_missing_info: Record<string, number>;
  co1_sub_parameters: Record<string, object>;
};

export type ConvertResultType = {
  Co1_result: string;
  Co1_score: string | number;
  ParamA_LVP_S: string | number;
  ParamA_LVP_J: string | number;
  ParamA_LVP_H: string | number;
  ParamB_LVP_S: string | number;
  ParamB_LVP_J: string | number;
  ParamB_LVP_H: string | number;
  ParamC_LVP_S: string | number;
  ParamC_LVP_J: string | number;
  ParamC_LVP_H: string | number;
  ParamD_LVP_S: string | number;
  ParamD_LVP_J: string | number;
  ParamD_LVP_H: string | number;
  ParamD_LVP_D: string | number;
  ParamD_LVP_DR: string | number;
  DSTP_AS: string | number;
  DSTP_PS: string | number;
  DSTP_PSA: string | number;
  DSTP_PSS: string | number;
  DSTP_DR: string | number;
};

const SUB_PARAMS_TITLE_LIST = ["Result.Dementia_score"];

const DEFAULT_DASHBOARD_MAIN_PARAM: ResultMainParam = {
  title: "Result.Dementia_rank",
  // border: "solid 3px #EFB6CB",
  value: undefined,
};

const ERROR_DASHBOARD_DATA: ResultDashboardData = {
  mainParam: DEFAULT_DASHBOARD_MAIN_PARAM,
  subParams: [
    {
      title: SUB_PARAMS_TITLE_LIST[0],
      value: undefined,
    },
  ],
  supplementaryInfo: [],
  diagramIndex: [],
  diagramParams: [],
};

export function ConvertResult({
  result,
}: {
  result: AnalysisResult;
}): ConvertResultType {
  if (!result) {
    return {
      Co1_result: "-",
      Co1_score: "-",
      ParamA_LVP_S: "-",
      ParamA_LVP_J: "-",
      ParamA_LVP_H: "-",
      ParamB_LVP_S: "-",
      ParamB_LVP_J: "-",
      ParamB_LVP_H: "-",
      ParamC_LVP_S: "-",
      ParamC_LVP_J: "-",
      ParamC_LVP_H: "-",
      ParamD_LVP_S: "-",
      ParamD_LVP_J: "-",
      ParamD_LVP_H: "-",
      ParamD_LVP_D: "-",
      ParamD_LVP_DR: "-",
      DSTP_AS: "-",
      DSTP_PS: "-",
      DSTP_PSA: "-",
      DSTP_PSS: "-",
      DSTP_DR: "-",
    };
  }
  const Co1_score = result.co1_score;
  type SubItem01 = {
    ParamA?: {
      LVP_S?: number;
      LVP_J?: number;
      LVP_H?: number;
    };
    ParamB?: {
      LVP_S?: number;
      LVP_J?: number;
      LVP_H?: number;
    };
    ParamC?: {
      LVP_S?: number;
      LVP_J?: number;
      LVP_H?: number;
    };
    ParamD: {
      LVP_S?: number;
      LVP_J?: number;
      LVP_H?: number;
      LVP_D?: number;
      LVP_DR: number;
    };
  };

  // type SubItem02 = {
  //   PAP?: number;
  // };

  type SubItem03 = {
    DSTP_AS?: number;
    DSTP_PS?: number;
    DSTP_PSA?: number;
    DSTP_PSS?: number;
    DSTP_DR: number;
  };

  // missing_infoがあるとco1_sub_parametersのデータ構造が変わる為、
  // データ型からサブパラメーターの種類を取得する
  const sampleSubItem01: SubItem01 = { ParamD: { LVP_DR: 0 } };
  // const sampleSubItem02: SubItem02 = { PAP: 0 };
  const sampleSubItem03: SubItem03 = { DSTP_DR: 0 };

  const isSubItem01 = (item: unknown): item is SubItem01 =>
    isOfType<SubItem01>(item, sampleSubItem01);
  const isSubItem03 = (item: unknown): item is SubItem03 =>
    isOfType<SubItem03>(item, sampleSubItem03);

  const DEFAULT_VALUE = 0.0;
  // 「長母音」
  let ParamA_LVP_S_pre = DEFAULT_VALUE; // 前半Shimmer
  let ParamA_LVP_J_pre = DEFAULT_VALUE; // 前半Jitter
  let ParamA_LVP_H_pre = DEFAULT_VALUE; // 前半HNR
  let ParamB_LVP_S_pre = DEFAULT_VALUE; // 中盤Shimmer
  let ParamB_LVP_J_pre = DEFAULT_VALUE; // 中盤Jitter
  let ParamB_LVP_H_pre = DEFAULT_VALUE; // 中盤HNR
  let ParamC_LVP_S_pre = DEFAULT_VALUE; // 後半Shimmer
  let ParamC_LVP_J_pre = DEFAULT_VALUE; // 後半Jitter
  let ParamC_LVP_H_pre = DEFAULT_VALUE; // 後半HNR
  let ParamD_LVP_S_pre = DEFAULT_VALUE; // 全体Shimmer
  let ParamD_LVP_J_pre = DEFAULT_VALUE; // 全体Jitter
  let ParamD_LVP_H_pre = DEFAULT_VALUE; // 全体HNR
  let ParamD_LVP_D_pre = DEFAULT_VALUE; // 全体DTW
  let ParamD_LVP_DR_pre = DEFAULT_VALUE; // 全体Duration

  // 「パタカ」
  let DSTP_AS_pre = DEFAULT_VALUE; // All Scope
  let DSTP_PS_pre = DEFAULT_VALUE; // Peak SD
  let DSTP_PSA_pre = DEFAULT_VALUE; // 発声の速さ
  let DSTP_PSS_pre = DEFAULT_VALUE; // 発声リズムの安定性
  let DSTP_DR_pre = DEFAULT_VALUE; // 「パタカ」の長さ

  for (const key in result.co1_sub_parameters) {
    if (Object.prototype.hasOwnProperty.call(result.co1_sub_parameters, key)) {
      const item = result.co1_sub_parameters[key];
      if (isSubItem01(item)) {
        ParamA_LVP_S_pre = item?.ParamA?.LVP_S ?? DEFAULT_VALUE;
        ParamA_LVP_J_pre = item?.ParamA?.LVP_J ?? DEFAULT_VALUE;
        ParamA_LVP_H_pre = item?.ParamA?.LVP_H ?? DEFAULT_VALUE;
        ParamB_LVP_S_pre = item?.ParamB?.LVP_S ?? DEFAULT_VALUE;
        ParamB_LVP_J_pre = item?.ParamB?.LVP_J ?? DEFAULT_VALUE;
        ParamB_LVP_H_pre = item?.ParamB?.LVP_H ?? DEFAULT_VALUE;
        ParamC_LVP_S_pre = item?.ParamC?.LVP_S ?? DEFAULT_VALUE;
        ParamC_LVP_J_pre = item?.ParamC?.LVP_J ?? DEFAULT_VALUE;
        ParamC_LVP_H_pre = item?.ParamC?.LVP_H ?? DEFAULT_VALUE;
        ParamD_LVP_S_pre = item?.ParamD?.LVP_S ?? DEFAULT_VALUE;
        ParamD_LVP_J_pre = item?.ParamD?.LVP_J ?? DEFAULT_VALUE;
        ParamD_LVP_H_pre = item?.ParamD?.LVP_H ?? DEFAULT_VALUE;
        ParamD_LVP_D_pre = item?.ParamD?.LVP_D ?? DEFAULT_VALUE;
        ParamD_LVP_DR_pre = item?.ParamD?.LVP_DR ?? DEFAULT_VALUE;
      } else if (isSubItem03(item)) {
        DSTP_AS_pre = item?.DSTP_AS ?? DEFAULT_VALUE;
        DSTP_PS_pre = item?.DSTP_PS ?? DEFAULT_VALUE;
        DSTP_PSA_pre = item?.DSTP_PSA ?? DEFAULT_VALUE;
        DSTP_PSS_pre = item?.DSTP_PSS ?? DEFAULT_VALUE;
        DSTP_DR_pre = item?.DSTP_DR ?? DEFAULT_VALUE;
      }
    }
  }

  const ParamA_LVP_S = isNaN(ParamA_LVP_S_pre)
    ? "-"
    : ParamA_LVP_S_pre.toFixed(4);
  const ParamA_LVP_J = isNaN(ParamA_LVP_J_pre)
    ? "-"
    : ParamA_LVP_J_pre.toFixed(4);
  const ParamA_LVP_H = isNaN(ParamA_LVP_H_pre)
    ? "-"
    : ParamA_LVP_H_pre.toFixed(2);
  const ParamB_LVP_S = isNaN(ParamB_LVP_S_pre)
    ? "-"
    : ParamB_LVP_S_pre.toFixed(4);
  const ParamB_LVP_J = isNaN(ParamB_LVP_J_pre)
    ? "-"
    : ParamB_LVP_J_pre.toFixed(4);
  const ParamB_LVP_H = isNaN(ParamB_LVP_H_pre)
    ? "-"
    : ParamB_LVP_H_pre.toFixed(2);
  const ParamC_LVP_S = isNaN(ParamC_LVP_S_pre)
    ? "-"
    : ParamC_LVP_S_pre.toFixed(4);
  const ParamC_LVP_J = isNaN(ParamC_LVP_J_pre)
    ? "-"
    : ParamC_LVP_J_pre.toFixed(4);
  const ParamC_LVP_H = isNaN(ParamC_LVP_H_pre)
    ? "-"
    : ParamC_LVP_H_pre.toFixed(2);
  const ParamD_LVP_S = isNaN(ParamD_LVP_S_pre)
    ? "-"
    : ParamD_LVP_S_pre.toFixed(4);
  const ParamD_LVP_J = isNaN(ParamD_LVP_J_pre)
    ? "-"
    : ParamD_LVP_J_pre.toFixed(4);
  const ParamD_LVP_H = isNaN(ParamD_LVP_H_pre)
    ? "-"
    : ParamD_LVP_H_pre.toFixed(2);
  const ParamD_LVP_D = isNaN(ParamD_LVP_D_pre)
    ? "-"
    : ParamD_LVP_D_pre.toFixed(1);
  const ParamD_LVP_DR = isNaN(ParamD_LVP_DR_pre)
    ? "-"
    : ParamD_LVP_DR_pre.toFixed(1);

  const DSTP_AS = isNaN(DSTP_AS_pre) ? "-" : DSTP_AS_pre.toFixed(1);
  const DSTP_PS = isNaN(DSTP_PS_pre) ? "-" : DSTP_PS_pre.toFixed(1);
  const DSTP_PSA = isNaN(DSTP_PSA_pre) ? "-" : DSTP_PSA_pre.toFixed(1);
  const DSTP_PSS = isNaN(DSTP_PSS_pre) ? "-" : DSTP_PSS_pre.toFixed(1);
  const DSTP_DR = isNaN(DSTP_DR_pre) ? "-" : DSTP_DR_pre.toFixed(1);

  return {
    Co1_result: result.co1_result,
    Co1_score: Co1_score,
    ParamA_LVP_S: ParamA_LVP_S,
    ParamA_LVP_J: ParamA_LVP_J,
    ParamA_LVP_H: ParamA_LVP_H,
    ParamB_LVP_S: ParamB_LVP_S,
    ParamB_LVP_J: ParamB_LVP_J,
    ParamB_LVP_H: ParamB_LVP_H,
    ParamC_LVP_S: ParamC_LVP_S,
    ParamC_LVP_J: ParamC_LVP_J,
    ParamC_LVP_H: ParamC_LVP_H,
    ParamD_LVP_S: ParamD_LVP_S,
    ParamD_LVP_J: ParamD_LVP_J,
    ParamD_LVP_H: ParamD_LVP_H,
    ParamD_LVP_D: ParamD_LVP_D,
    ParamD_LVP_DR: ParamD_LVP_DR,
    DSTP_AS: DSTP_AS,
    DSTP_PS: DSTP_PS,
    DSTP_PSA: DSTP_PSA,
    DSTP_PSS: DSTP_PSS,
    DSTP_DR: DSTP_DR,
  };
}

// /analysis/co1のレスポンスにおけるCo1スコアのパス
export const AnalysisResultCo1ScorePath = "co1_score";

interface EngineProps {
  _analysisType?: AnalysisEngineType | null;
}

export class Co1Engine extends BaseAnalysisEngine<AnalysisResult> {
  _resultCaches: Record<string, AnalysisResult> = {};
  _engineType: ApiEngineType = "co1";
  _apiPath = "/analysis/co1";
  _analysisType: AnalysisEngineType | null = null;

  constructor(props?: EngineProps) {
    super();
    if (props && props._analysisType) {
      this._analysisType = props._analysisType;
    }
  }

  async getAverage(companyId?: string): Promise<MeanResult | null> {
    const params = {
      engine: "co1",
      data_path: "co1_score",
      company_id: companyId,
    };
    try {
      const response = await appAxios.get("/analysis/statistics/mean", {
        params,
      });
      return response.data;
    } catch {
      return null;
    }
  }

  private static convertToDashboardData(
    result: AnalysisResult
  ): ResultDashboardData {
    const mainParam: ResultMainParam = {
      ...DEFAULT_DASHBOARD_MAIN_PARAM,
      value: result.co1_result,
    };
    const subParams: ResultSubParam[] = [
      {
        title: SUB_PARAMS_TITLE_LIST[0],
        value: result.co1_score,
      },
    ];

    return {
      mainParam,
      subParams,
      supplementaryInfo: [],
      diagramIndex: [],
      diagramParams: [],
    };
  }

  async renderResult(
    key: number,
    fileId: string,
    t: TFunction
  ): Promise<ReactElement> {
    const result = await this.getResult(fileId);
    return this._analysisType === "Co1Demo" ? (
      <ResultCo1Demo
        key={key}
        title={t("Result.Dementia_analysis_result")}
        co1_result={result ? result.co1_result : ""}
      />
    ) : (
      <ResultDashboard
        key={key}
        title={t("Result.Dementia_analysis_result") + "(Co-1)"}
        result={
          result
            ? Co1Engine.convertToDashboardData(result)
            : ERROR_DASHBOARD_DATA
        }
      />
    );
  }
}
