import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import enTranslation from "./translations/en.json";
import jaTranslation from "./translations/ja.json";
import { DEFAULT_LANGUAGE } from "../environments";

const resources = {
  en: { translation: enTranslation },
  ja: { translation: jaTranslation },
};

i18n.on("initialized", () => {
  document.documentElement.setAttribute("lang", i18n.language);
});

i18n.on("languageChanged", (lang) => {
  document.documentElement.setAttribute("lang", lang);
});

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources,
    fallbackLng: "en",
    lng: DEFAULT_LANGUAGE,
    interpolation: {
      escapeValue: false,
    },
  })
  .then();

export default i18n;
