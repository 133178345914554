import { useToastMessage } from "./useToastMessage";
import { useTranslation } from "react-i18next";
import { useCallback } from "react";
import { AudioRecorder } from "../utils/audioRecorder/audioRecorder";
import { CheckVolumeResult } from "../types";
import { useAtom } from "jotai";
import { detectedVolumeByPhraseVisualizerAtom } from "../store";
import { volumeToMimosysVolume } from "../utils/calcVolume";

type CheckVolumeHook = (
  audioData: Float32Array,
  samplingRate: number,
  detectVolume: number,
  detectLengthMSec?: number
) => Promise<CheckVolumeResult>;

export function useCheckVolume(): CheckVolumeHook {
  const toastMessage = useToastMessage();
  const toastWarning = useToastMessage();
  const { t } = useTranslation();
  const [isDetectedVolumeByPhraseVisualizer] = useAtom(
    detectedVolumeByPhraseVisualizerAtom
  );

  return useCallback<CheckVolumeHook>(
    async (audioData, samplingRate, detectVolume, detectLengthMSec) => {
      const isAllDataSilent = audioData.every((datum) => datum === 0);
      if (isAllDataSilent) {
        return "Error.allDataIsSilent";
      }

      if (!isDetectedVolumeByPhraseVisualizer) {
        toastMessage(t("Error.belowVolumeThreshold"));
        return "Error.belowVolumeThreshold";
      }

      const isDetectedVoice = AudioRecorder.detectVoice(
        audioData,
        samplingRate,
        volumeToMimosysVolume(detectVolume),
        detectLengthMSec
      );
      if (!isDetectedVoice) {
        toastMessage(t("Error.cannotDetectVoice"));
        return "Error.cannotDetectVoice";
      }

      const maxVolume = audioData.reduce(function (a, b) {
        return Math.max(a, b);
      }, -Infinity);
      if (maxVolume === 1) {
        toastWarning(t("Error.speakTooLoud"), "warning");
      }

      return "OK";
    },
    [isDetectedVolumeByPhraseVisualizer, t, toastMessage, toastWarning]
  );
}
