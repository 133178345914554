import { ReactElement } from "react";
import { Box, Button, VStack } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { IoHome } from "react-icons/io5";
import { useTranslation } from "react-i18next";
import { Layout } from "../../../components/atoms/Layout";
import { useLocation } from "react-router-dom";
import { isValidAnalysisErrorReason } from "../../../types";

export function AnalysisError(): ReactElement {
  const { i18n, t } = useTranslation();
  const { state } = useLocation();
  const isReason = isValidAnalysisErrorReason(state);
  const errorResponseCode = isReason ? state.code : "Unknown";
  const errorResponseMessage = isReason ? state.message : "Unknown";

  return (
    <Layout>
      <Layout.Title textAlign="center" mb={10}>
        {t("AnalysisError.HeadingTitle")}
      </Layout.Title>
      <VStack spacing={10} align="center" marginTop="1em">
        <Box>code : {errorResponseCode}</Box>
        <Box>message : {errorResponseMessage}</Box>
        <Button
          as={Link}
          to={`/${i18n.language}/home`}
          variant="btn_primary"
          px={8}
          leftIcon={<IoHome />}
        >
          {t("AnalysisError.Back_to_Home")}
        </Button>
      </VStack>
    </Layout>
  );
}
