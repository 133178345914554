import { RecorderBuffer } from "./audioRecorderBuffer";

export type RecorderNodeType = "Worklet" | "Script";

export class WebAudioWrapper {
  protected _stream: MediaStream | null = null;
  protected _context: AudioContext | null = null;
  protected _mediaStreamSource: MediaStreamAudioSourceNode | null = null;
  protected _recorderBuffer: RecorderBuffer;
  protected _recorderNode: AudioNode | null = null;

  get samplingRate(): number | undefined {
    return this._context?.sampleRate;
  }

  constructor(buffer: RecorderBuffer) {
    this._recorderBuffer = buffer;
  }

  protected static createConstraints(): MediaTrackConstraints {
    const supports = navigator.mediaDevices.getSupportedConstraints();
    if (import.meta.env) {
      console.log("getSupportedConstraints");
      console.log(supports);
    }

    let constraints = {};

    // MEMO Pixel5a(Android13)で echoCancellation: falseにすると
    // 音声が壊れる事象があったので、Pixel 5aのときのみechoCancellation: trueとする
    if (
      supports.echoCancellation &&
      !navigator.userAgent.includes("Pixel 5a")
    ) {
      constraints = { ...constraints, echoCancellation: false };
    }
    if (supports.autoGainControl) {
      constraints = { ...constraints, autoGainControl: false };
    }
    if (supports.noiseSuppression) {
      constraints = { ...constraints, noiseSuppression: false };
    }
    return constraints;
  }

  getType(): RecorderNodeType | undefined {
    return undefined;
  }

  protected static async createStream(): Promise<MediaStream> {
    const stream = await navigator.mediaDevices.getUserMedia({
      audio: WebAudioWrapper.createConstraints(),
      video: false,
    });

    return stream;
  }

  protected static createMediaStreamSource(
    stream: MediaStream,
    context: AudioContext
  ): MediaStreamAudioSourceNode {
    return context.createMediaStreamSource(stream);
  }

  protected connect(): void {
    if (this._context && this._mediaStreamSource && this._recorderNode) {
      this._mediaStreamSource
        .connect(this._recorderNode)
        .connect(this._context.destination);
    }
  }

  protected disconnect(): void {
    this._recorderNode?.disconnect();
    this._mediaStreamSource?.disconnect();
  }

  canStart(): boolean {
    return false;
  }

  async prepareRecording(): Promise<void> {
    return;
  }

  startRecording(): void {
    return;
  }

  async stopRecording(): Promise<void> {
    return;
  }

  async close(): Promise<void> {
    if (this._stream) {
      this._stream.getTracks().forEach((track) => track.stop());
      await this._context?.close();

      this._stream = null;
      this._context = null;
    }
  }
}

export class ScriptWrapper extends WebAudioWrapper {
  private static createNode(
    context: AudioContext,
    buffer: RecorderBuffer
  ): ScriptProcessorNode {
    const node = context.createScriptProcessor(undefined, 1, 1);
    node.onaudioprocess = (e) =>
      buffer.pushData(e.inputBuffer.getChannelData(0).slice(0));
    return node;
  }

  getType(): RecorderNodeType {
    return "Script";
  }

  async prepareRecording(): Promise<void> {
    this._stream = await ScriptWrapper.createStream();
  }

  canStart(): boolean {
    return this._stream !== null && this._context === null;
  }

  startRecording(): void {
    if (this._stream && !this._context) {
      this._context = new AudioContext();
      this._recorderNode = ScriptWrapper.createNode(
        this._context,
        this._recorderBuffer
      );
      this._mediaStreamSource = ScriptWrapper.createMediaStreamSource(
        this._stream,
        this._context
      );
      this.connect();
    }
  }

  async stopRecording(): Promise<void> {
    this.disconnect();

    // MEMO iOS15ではmediaStreamがエラーになることがあるため
    // クロースと再初期化処理を行う必要がある
    await this.close();
    await this.prepareRecording();
  }
}

export class WorkletWrapper extends WebAudioWrapper {
  protected _canStart = false;
  static WORKLET_URL: string;

  getType(): RecorderNodeType {
    return "Worklet";
  }

  private static async createNode(
    context: AudioContext,
    buffer: RecorderBuffer
  ): Promise<AudioWorkletNode> {
    await context.audioWorklet.addModule(this.WORKLET_URL);
    const node = new AudioWorkletNode(context, "recorder-processor");
    node.port.onmessage = (e) => buffer.pushData(e.data);
    return node;
  }

  canStart(): boolean {
    return this._canStart;
  }

  async prepareRecording(): Promise<void> {
    this._stream = await ScriptWrapper.createStream();
    this._context = new AudioContext();
    this._recorderNode = await WorkletWrapper.createNode(
      this._context,
      this._recorderBuffer
    );
    this._mediaStreamSource = WorkletWrapper.createMediaStreamSource(
      this._stream,
      this._context
    );
    this._canStart = true;
  }

  startRecording(): void {
    this._canStart = false;
    this.connect();
  }

  async stopRecording(): Promise<void> {
    this.disconnect();
    this._canStart = true;
  }
}
