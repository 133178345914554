import { forwardRef, Switch, SwitchProps } from "@chakra-ui/react";

export const PrimarySwitch = forwardRef<SwitchProps, "input">((props, ref) => {
  return (
    <Switch
      sx={{
        ".chakra-switch__track[data-checked]": {
          backgroundColor: "primary",
        },
      }}
      ref={ref}
      {...props}
    />
  );
});
