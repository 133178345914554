import { ReactElement, useEffect } from "react";
import { Button, Center, Heading, useTheme } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { IoHome } from "react-icons/io5";
import { useTranslation } from "react-i18next";
import { Layout } from "../components/atoms/Layout";

export function NotFound(): ReactElement {
  const { i18n } = useTranslation();

  const theme = useTheme();
  const backgroundColor = theme.colors.primary["bg_lv1"];

  useEffect(() => {
    document.body.style.backgroundColor = backgroundColor;
    return () => {
      document.body.style.backgroundColor = "white";
    };
  }, [backgroundColor]);

  return (
    <Layout>
      <Heading as="h1" textAlign="center" mb={10}>
        404 | Page Not Found
      </Heading>
      <Center>
        <Button
          as={Link}
          to={`/${i18n.language}/home`}
          variant="btn_primary"
          px={8}
          leftIcon={<IoHome />}
        >
          Back to Home
        </Button>
      </Center>
    </Layout>
  );
}
