import { ReactElement, useEffect, useRef } from "react";
import { Box, Image, useTheme } from "@chakra-ui/react";
import { APP_TYPE, BANNER_IMAGE_URL, APP_NAME } from "../../environments";
import { ReactSVG } from "react-svg";
import { setSVGFillColor } from "../../utils/svgHelper";

export function BannerImage(): ReactElement {
  const theme = useTheme();
  const boxRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const svg = boxRef.current?.querySelector("svg");
    if (svg) {
      const height = svg.getBoundingClientRect().height;
      console.log(height);
      if (boxRef.current) {
        boxRef.current.style.height = `${height}px`;
      }
    }
  }, []);
  return (
    <>
      {APP_TYPE === "base" && (
        <Box
          ref={boxRef}
          pb={10}
          alignItems="center"
          justifyContent="center"
          width="full"
        >
          <ReactSVG
            src={BANNER_IMAGE_URL}
            beforeInjection={(svg) => {
              svg.removeAttribute("width");
              svg.removeAttribute("height");
              svg.style.width = "100%";
              svg.style.height = "auto";
              const title = document.createElementNS(
                "http://www.w3.org/2000/svg",
                "title"
              );
              title.textContent = APP_NAME;
              svg.prepend(title);
              setSVGFillColor(svg, ".cls-1", theme.colors.primary["theme_lv1"]);
            }}
          />
        </Box>
      )}
      {APP_TYPE !== "base" && (
        <Image src={BANNER_IMAGE_URL} alt={APP_NAME} pb={10} mx="auto" />
      )}
    </>
  );
}
