import { ReactElement } from "react";
import { IconButton, Icon, useMediaQuery } from "@chakra-ui/react";
import { IoChevronBack } from "react-icons/io5";
import { useNavigate } from "react-router-dom";

export function BackButton(): ReactElement {
  const navigate = useNavigate();
  const [isTouchDevice] = useMediaQuery("(hover: none)");

  return (
    <IconButton
      position="absolute"
      aria-label="Return"
      variant="ghost"
      width={5}
      left={0}
      icon={<Icon as={IoChevronBack} boxSize={6} color="primary.theme_lv1" />}
      _hover={isTouchDevice ? { bg: "white" } : undefined}
      onClick={() => navigate(-1)}
    />
  );
}
