import { ReactElement } from "react";
import { DysphagiaResultSummary } from "../../types";
import { useTranslation } from "react-i18next";
import { Box, Flex } from "@chakra-ui/react";
import { DysphagiaResult } from "../atoms/DysphagiaResult";
import { AnalysisResultCardLayout } from "../atoms/AnalysisResultCardLayout";

type Props = {
  title: string;
  summary: DysphagiaResultSummary;
};

export function DysphagiaResultCard(props: Props): ReactElement {
  const { t } = useTranslation();
  return (
    <AnalysisResultCardLayout title={props.title}>
      <Flex gap={2}>
        {props.summary.supplementaryInfo.map((info, infoIndex) => {
          return (
            <Box key={infoIndex} fontSize="12px">
              {t(info.title) +
                ":" +
                (info.value !== undefined ? info.value : "Error")}
              {infoIndex !== props.summary.supplementaryInfo.length - 1 && ", "}
            </Box>
          );
        })}
      </Flex>
      <DysphagiaResult summary={props.summary} />
    </AnalysisResultCardLayout>
  );
}
