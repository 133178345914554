import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
  useMemo,
} from "react";
import { ChakraProviderProps, useTheme } from "@chakra-ui/react";
import { SoundVisualizerRef } from "../../types";
import { detectedVolumeByPhraseVisualizerAtom } from "../../store";
import { useAtom } from "jotai";
import { volumeToDecibel } from "../../utils/calcVolume";

type Props = {
  maxValue: number;
  underThreshold: number;
  upperThreshold: number;
  height?: string;
  width?: string;
  maxWidth?: string;
};

export const CircleSoundVisualizer = forwardRef<SoundVisualizerRef, Props>(
  (props, parentRef) => {
    const theme = useTheme();
    const canvasRef = useRef<HTMLCanvasElement | null>(null);
    const latestRawData = useRef(0);
    const containerRef = useRef<HTMLDivElement>(null);
    const [[width, height], setComponentSize] = useState([0, 0]);
    const [isDetectedVolumeAtom, setIsDetectedVolume] = useAtom(
      detectedVolumeByPhraseVisualizerAtom
    );

    const colorBlindnessColors = useMemo(
      () => [
        theme.colors.primary["theme_lv2"],
        theme.colors.primary["theme_lv2"],
        theme.colors.primary["theme_lv2"],
        theme.colors.primary["accent"],
      ],
      [theme]
    );

    const getContext = (): CanvasRenderingContext2D | null => {
      return canvasRef.current?.getContext("2d") || null;
    };

    const drawObject = useCallback(
      (rawData: number, isTooLoud: boolean, isDetectedVolume: boolean) => {
        const clearCanvas = (): void => {
          const ctx = getContext();
          if (!ctx) return;
          ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
        };

        const ctx = getContext();
        if (!ctx) return;

        ctx.canvas.style.width = width + "px";
        ctx.canvas.style.height = height + "px";

        const canvasWidth = ctx.canvas.width;
        const canvasHeight = ctx.canvas.height;

        const color = theme.colors.primary["theme_lv1"];

        const rawLevel = calcVolumeLevel(
          rawData,
          props.underThreshold,
          props.upperThreshold,
          props.maxValue,
          isTooLoud,
          isDetectedVolume
        );
        // 一度でも発話検知基準を満たしていればOK
        if (!isDetectedVolumeAtom) {
          setIsDetectedVolume(isDetectedVolume);
        }

        clearCanvas();
        drawRippleVolume(
          ctx,
          canvasWidth,
          canvasHeight,
          color,
          rawLevel,
          colorBlindnessColors,
          theme
        );
      },
      [
        width,
        height,
        theme,
        props.underThreshold,
        props.upperThreshold,
        props.maxValue,
        isDetectedVolumeAtom,
        colorBlindnessColors,
        setIsDetectedVolume,
      ]
    );

    useImperativeHandle(
      parentRef,
      () => ({
        draw: (rawData, isTooLoud, isDetectedVolume) => {
          drawObject(rawData, isTooLoud, isDetectedVolume);
          latestRawData.current = rawData;
        },
      }),
      [drawObject]
    );

    useEffect(() => {
      const observer = new ResizeObserver((entries) => {
        setComponentSize([
          entries[0].contentRect.width,
          entries[0].contentRect.height,
        ]);
        drawObject(latestRawData.current, false, false);
      });
      if (containerRef.current) {
        observer.observe(containerRef.current);
      }

      return () => observer.disconnect();
    }, [drawObject]);

    return (
      <div
        ref={containerRef}
        style={{
          height: props.height,
          width: props.width,
          maxWidth: props.maxWidth,
          margin: "0 auto",
        }}
      >
        <div style={{ height: height, width: width }}>
          <canvas ref={canvasRef} height={height * 2} width={width * 2} />
        </div>
      </div>
    );
  }
);
CircleSoundVisualizer.displayName = "CircleSoundVisualizer";

function drawRippleVolume(
  ctx: CanvasRenderingContext2D,
  w: number,
  h: number,
  color: string,
  volumeLevel: number,
  colorBlindnessColors: string[],
  theme: ChakraProviderProps["theme"]
): void {
  const isWidthUpper = h <= w;

  const centerY = h / 2;
  const centerX = w / 2;
  const firstRadius = isWidthUpper ? h * 0.35 : w * 0.35;
  const rippleWidthAll = isWidthUpper ? h * 0.15 : w * 0.15;
  const rippleWidth = rippleWidthAll / 5;
  ctx.lineWidth = rippleWidth;

  ctx.moveTo(centerX + firstRadius, centerY);
  ctx.beginPath();
  ctx.lineWidth = 3;
  ctx.strokeStyle = `${color}`;
  ctx.arc(centerX, centerY, firstRadius, 0, Math.PI * 2);
  if (theme) {
    ctx.fillStyle = theme.colors.common.base;
    ctx.fill();
  }
  ctx.stroke();

  if (volumeLevel >= 1) {
    ctx.moveTo(centerX + firstRadius + 2, centerY);
    ctx.beginPath();
    ctx.lineWidth = rippleWidth;
    ctx.strokeStyle = `primary.theme_lv1`;
    ctx.arc(centerX, centerY, firstRadius + 2, 0, Math.PI * 2);
    ctx.stroke();

    for (let rippleCount = 1; rippleCount < volumeLevel; rippleCount++) {
      ctx.moveTo(
        centerX + (firstRadius + 2) + rippleWidth * rippleCount,
        centerY
      );
      ctx.beginPath();
      ctx.strokeStyle = colorBlindnessColors[rippleCount - 1];
      ctx.arc(
        centerX,
        centerY,
        firstRadius + 2 + rippleWidth * rippleCount,
        0,
        Math.PI * 2
      );
      ctx.stroke();
    }
  }
}

function calcVolumeLevel(
  volume: number,
  underThreshold: number,
  upperThreshold: number,
  maxValue: number,
  isTooLoud: boolean,
  isDetectedVolume: boolean
): number {
  const relativeVolume = volumeToDecibel(volume) / volumeToDecibel(maxValue);
  if (relativeVolume === 0) {
    return 0;
  } else if (isTooLoud) {
    return 5;
  } else if (!isDetectedVolume) {
    return 1;
  } else if (relativeVolume < underThreshold) {
    return 1;
  } else if (
    relativeVolume <
    underThreshold + (upperThreshold - underThreshold) * 0.33
  ) {
    return 2;
  } else if (
    relativeVolume <
    underThreshold + (upperThreshold - underThreshold) * 0.66
  ) {
    return 3;
  } else {
    return 4;
  }
}
