export const theme_common = {
  common_text: {
    dark: "#4D4D4D",
    gray: "#B9B9B9",
    light: "#FFFFFF",
  },
  login: {
    login_button_bg: "#E6E6E6", // 通常ログイン/選択ログイン切り替えタブの背景色
    login_textbox_bg: "#E8F0FE", // テキストボックスの背景色
  },
  trend: {
    usual: "#9494eb", // こころの活量値（いつも通り）
    good: "#4fd898", // こころの活量値（良い調子）
    bad: "#62bdef", // こころの活量値（疲れ気味）
  },
  mi1Result: {
    primary: "#7BCBD7", // メインカラー
    bg: "#E5ECFA", // 背景色
  },
  mentalActivity: {
    primary: "#F2CA95", // メインカラー
    bg: "#F2CA954D", // 背景色
    sdArea: "#F2CA9533", // グラフ中の1.5SDエリア色
  },
  vitality: {
    primary: "#EFB6CB", // メインカラー
    bg: "#EFB6CB4D", // 背景色
    sdArea: "#EFB6CB33", // グラフ中の1.5SDエリア色
  },
  graph: {
    referenceLine: "#f44336", // グラフに表示する平均値線の色
  },
};
