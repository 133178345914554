export class RecorderBuffer {
  private _buffers: Float32Array[];
  private loadedIndex: number;

  constructor() {
    this._buffers = [];
    this.loadedIndex = 0;
  }

  static mergeBuffers(buffers: Float32Array[]): Float32Array {
    const sampleLength = buffers.reduce((acc, buf) => acc + buf.length, 0);
    const array = new Float32Array(sampleLength);
    let sampleIdx = 0;
    for (let i = 0; i < buffers.length; i++) {
      for (let j = 0; j < buffers[i].length; j++) {
        array[sampleIdx] = buffers[i][j];
        sampleIdx++;
      }
    }
    return array;
  }

  pushData(data: Float32Array): void {
    this._buffers.push(data);
  }

  getData(): Float32Array {
    const buffer = this._buffers.slice(this.loadedIndex);
    this.loadedIndex = this._buffers.length;
    return RecorderBuffer.mergeBuffers(buffer);
  }

  getAllData(): Float32Array {
    return RecorderBuffer.mergeBuffers(this._buffers);
  }

  clear(): void {
    this.loadedIndex = 0;
    this._buffers = [];
  }
}
