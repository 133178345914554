export function formatApiResponse(
  val: number | string | undefined,
  decimal = 0
): string {
  if (val === null || val === undefined || val === "") {
    return "-";
  } else if (typeof val === "number") {
    return val.toFixed(decimal);
  } else {
    const num = parseFloat(val);
    return isNaN(num) ? val : num.toFixed(decimal);
  }
}
