import { BaseAnalysisEngine, BaseAnalysisResult } from "./baseAnalysisEngine";
import { ReactElement } from "react";
import { TFunction } from "react-i18next";
import { ResultDashboard } from "../components/molecules/ResultDashboard";
import {
  AnalysisEngineType,
  ApiEngineType,
  ResultDashboardData,
  ResultDiagramIndex,
  ResultMainParam,
  ResultSubParam,
  ResultSupplementaryInfo,
} from "../types";
import { ResultMi2Demo } from "../components/molecules/ResultMi2Demo";

type MI2_DATA = {
  success: boolean;
  zh_value: number;
  cpf_value: number;
  hurst_exponent: number;
  zero_cross_rate: number;
  wav_filename: string;
  start_time: number;
  end_time: number;
};

export type AnalysisResult = BaseAnalysisResult & {
  utterances: number;
  mi2_result: string;
  zh_score: number;
  cpf_score: number;
  mi2_data: MI2_DATA[];
};

const DEFAULT_DASHBOARD_MAIN_PARAM: ResultMainParam = {
  title: "Result.Mi2_score",
  // border: "solid 3px #F2CA95",
  value: undefined,
};

const SUB_PARAMS_TITLE_LIST = ["Result.Mi2_cpf", "Result.Mi2_zh"];

const SUPPLEMENTARY_INFO_TITLE_LIST = [
  "Result.analyze_files",
  "Result.analyze_utterances",
];

export function ConvertResult({
  result,
}: {
  result: AnalysisResult;
}): Record<string, number | string> {
  if (!result) {
    return {
      zh_score: "-",
      cpf_score: "-",
    };
  }
  const zh_score = Math.floor(result.zh_score * 100);
  const cpf_score = Math.floor(result.cpf_score * 100);
  return {
    zh_score: zh_score,
    cpf_score: cpf_score,
  };
}

interface EngineProps {
  _analysisType?: AnalysisEngineType | null;
}

export class Mi2Engine extends BaseAnalysisEngine<AnalysisResult> {
  _resultCaches: Record<string, AnalysisResult> = {};
  _engineType: ApiEngineType = "mi2";
  _apiPath = "/analysis/mi2";
  _analysisType: AnalysisEngineType | null = null;

  constructor(props?: EngineProps) {
    super();
    if (props && props._analysisType) {
      this._analysisType = props._analysisType;
    }
  }

  private static dashboardDiagramIndex(t: TFunction): ResultDiagramIndex[] {
    return [
      { width: "32px", title: "No" },
      { width: "48%", title: t("Result.table_phrase"), align: "left" },
      { width: "10%", title: "cpf" },
      { width: "10%", title: "z-h" },
      { width: "12%", title: "hurst" },
      { width: "10%", title: "zcr" },
      { width: "10%", title: "sec" },
    ];
  }

  private static errorDashboardData(t: TFunction): ResultDashboardData {
    return {
      mainParam: DEFAULT_DASHBOARD_MAIN_PARAM,
      subParams: [
        {
          title: SUB_PARAMS_TITLE_LIST[0],
          value: undefined,
        },
        {
          title: SUB_PARAMS_TITLE_LIST[1],
          value: undefined,
        },
      ],
      supplementaryInfo: [
        {
          title: SUPPLEMENTARY_INFO_TITLE_LIST[0],
          value: undefined,
        },
        {
          title: SUPPLEMENTARY_INFO_TITLE_LIST[1],
          value: undefined,
        },
      ],
      diagramIndex: this.dashboardDiagramIndex(t),
      diagramParams: [],
    };
  }

  private static convertToDashboardData(
    result: AnalysisResult,
    t: TFunction
  ): ResultDashboardData {
    const phrases = result.voice_profiles.voices.map((voice) =>
      voice.phrase ? voice.phrase : "Error"
    );
    const diagramParams = BaseAnalysisEngine.generateResultDiagramParams(
      result.mi2_data,
      (param, recordId, phraseIndex) => [
        { width: "32px", value: recordId, align: "left" },
        { width: "48%", value: phrases[phraseIndex], align: "left" },
        {
          width: "10%",
          value: Math.floor(param.cpf_value * 100),
        },
        {
          width: "10%",
          value: Math.floor(param.zh_value * 100),
        },
        {
          width: "12%",
          value: Math.floor(param.hurst_exponent * 100),
        },
        {
          width: "10%",
          value: Math.floor(param.zero_cross_rate * 100),
        },
        {
          width: "10%",
          value: ((param.end_time - param.start_time) / 1000).toFixed(1),
        },
      ]
    );
    const mainParam: ResultMainParam = {
      ...DEFAULT_DASHBOARD_MAIN_PARAM,
      value: result.mi2_result,
    };

    const subParams: ResultSubParam[] = [
      {
        title: SUB_PARAMS_TITLE_LIST[0],
        value: `${Math.floor(result.cpf_score * 100)}(${
          result.cpf_score >= 0.42 ? "Nega" : "Posi"
        })`,
        color: `${result.cpf_score >= 0.42 ? "black" : "red"}`,
      },
      {
        title: SUB_PARAMS_TITLE_LIST[1],
        value: `${Math.floor(result.zh_score * 100)}(${
          result.zh_score >= 0.34 ? "Nega" : "Posi"
        })`,
        color: `${result.zh_score >= 0.34 ? "black" : "red"}`,
      },
    ];

    const supplementaryInfo: ResultSupplementaryInfo[] = [
      {
        title: SUPPLEMENTARY_INFO_TITLE_LIST[0],
        value: result.voice_profiles.voices.length,
      },
      {
        title: SUPPLEMENTARY_INFO_TITLE_LIST[1],
        value: result.utterances,
      },
    ];

    return {
      mainParam,
      subParams,
      supplementaryInfo,
      diagramIndex: this.dashboardDiagramIndex(t),
      diagramParams,
    };
  }

  async renderResult(
    key: number,
    fileId: string,
    t: TFunction
  ): Promise<ReactElement> {
    const result = await this.getResult(fileId);
    const dashboardData = result
      ? Mi2Engine.convertToDashboardData(result, t)
      : Mi2Engine.errorDashboardData(t);

    return this._analysisType === "Mi2Demo" ? (
      <ResultMi2Demo key={key} result={dashboardData} />
    ) : (
      <ResultDashboard
        key={key}
        title={t("Result.Mi2_analysis_result")}
        result={dashboardData}
      />
    );
  }
}
