import React, { createContext, ReactNode, useContext, useState } from "react";
import {
  AlertProps,
  extendTheme,
  ChakraProvider,
  ChakraProviderProps,
} from "@chakra-ui/react";
import { useAtom } from "jotai";
import { themeColorAtom } from "../../store";
import { themeType as themeColorType } from "../../types";
import { theme_common } from "../../assets/colors/common";
import { theme_sakura_light } from "../../assets/colors/sakura_light";
import { theme_uix_light } from "../../assets/colors/uix_light";
import { theme_ntt_light } from "../../assets/colors/ntt_light";
import { APP_TYPE } from "../../environments";

type ThemeType = ChakraProviderProps["theme"];
type SetThemeType = (themeName: themeColorType) => void;

const ThemeContext = createContext<[ThemeType, SetThemeType] | undefined>(
  undefined
);

const activeButtonStyle = {
  _active: {
    color: "primary.theme_lv1",
    bg: "common.base",
    borderColor: "primary.theme_lv1",
  },
};

const disableButtonStyle = {
  _disabled: {
    color: "common_text.light",
    bgColor: "primary.theme_lv2",
    borderColor: "transparent",
  },
};

const btn_commonStyle = {
  color: "common_text.light",
  bgColor: "primary.theme_lv1",
  borderColor: "primary.theme_lv1",
  borderWidth: "2px",
  rounded: "full",
  "@media (hover: hover) and (pointer: fine)": {
    _hover: {
      color: "common_text.light",
      bgColor: "primary.theme_lv1",
      opacity: 0.8,
      ...disableButtonStyle,
    },
    ...activeButtonStyle,
    ...disableButtonStyle,
  },
  "@media (hover: none)": {
    _hover: {
      // タッチデバイスではホバー時のスタイルを無視
      ...disableButtonStyle,
    },
    ...activeButtonStyle,
    ...disableButtonStyle,
  },
};

const commonStyle = extendTheme({
  colors: theme_common,
  styles: {
    global: {
      body: {
        color: "text.main_text_lv1",
      },
    },
  },
  components: {
    Button: {
      variants: {
        btn_primary: () => {
          return {
            minHeight: "64px",
            ...btn_commonStyle,
          };
        },
        btn_secondary: () => {
          return {
            ...btn_commonStyle,
          };
        },
      },
    },
    Alert: {
      baseStyle: (props: AlertProps) => {
        const { status } = props;
        const infoBase = status === "warning" && {
          container: {
            color: "common.base",
            background: "unset",
            backgroundColor: "#FFC107",
          },
        };
        return {
          ...infoBase,
        };
      },
    },
  },
  fonts: {
    heading: `Noto Sans JP, Oswald`,
    body: `Noto Sans JP, Oswald`,
  },
});

const themeSakuraLight = extendTheme(commonStyle, {
  colors: theme_sakura_light,
});

const themeUIXLight = extendTheme(commonStyle, {
  colors: theme_uix_light,
});

const themeNTTLight = extendTheme(commonStyle, {
  colors: theme_ntt_light,
});

export const theme_list = {
  sakura_light: themeSakuraLight,
  uix_light: themeUIXLight,
  ntt_light: themeNTTLight,
};

interface ThemeProviderProps {
  children: ReactNode;
}

function getTheme(themeName: themeColorType): ThemeType {
  let theme = theme_list[themeName] ?? theme_list["sakura_light"];
  if (APP_TYPE === "uix") {
    theme = theme_list["uix_light"];
  } else if (APP_TYPE === "ntt") {
    theme = theme_list["ntt_light"];
  }
  return theme;
}

export const ThemeProvider: React.FC<ThemeProviderProps> = ({ children }) => {
  const [themeColor] = useAtom(themeColorAtom);
  const currentTheme = getTheme(themeColor);
  const [theme, _setTheme] = useState<ThemeType>(currentTheme);

  const setTheme = (themeName: themeColorType): void => {
    _setTheme(getTheme(themeName));
  };

  return (
    <ThemeContext.Provider value={[theme, setTheme]}>
      <ChakraProvider theme={theme}>{children}</ChakraProvider>
    </ThemeContext.Provider>
  );
};

export function useTheme(): [ThemeType, SetThemeType] {
  const context = useContext(ThemeContext);
  if (!context) {
    throw new Error(`useTheme must be used within a ThemeProvider`);
  }
  return context;
}
