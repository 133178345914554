import { ReactElement, useContext, useEffect } from "react";
import { Link, Navigate } from "react-router-dom";
import {
  Box,
  Flex,
  Link as ChakuraLink,
  Text,
  useTheme,
  Button,
  Icon,
} from "@chakra-ui/react";
import { Layout } from "../../../components/atoms/Layout";
import { ReactSVG } from "react-svg";
import AnalyzeVoiceImgUrl from "../../../assets/images/base/analyze_voice.svg";
import AnalyzeFileImgUrl from "../../../assets/images/base/analyze_file.svg";
import { useTranslation } from "react-i18next";
import { setSVGFillColor } from "../../../utils/svgHelper";
import { ImageContext } from "../../../components/atoms/ImageProvider";
import { useAtom } from "jotai";
import { questionnaireAtom } from "../../../store";
import i18n from "../../../i18n";
import { SKIP_QUESTIONNAIRES } from "../../../environments";

export function CreateImageButton(props: {
  linkTo: string;
  imgUrl: string;
  text: string;
}): ReactElement {
  const analyzeIcon = (): ReactElement => (
    <ReactSVG
      beforeInjection={(svg) => {
        svg.setAttribute("style", "width: 132px; height: 132px");
        setSVGFillColor(svg, ".cls-1", "currentColor");
      }}
      src={props.imgUrl}
    />
  );
  return (
    <ChakuraLink
      as={Link}
      to={props.linkTo}
      _hover={{ textDecoration: "none" }}
      width="100%"
    >
      <Button
        variant="btn_secondary"
        width="100%"
        minHeight="209px"
        p={4}
        rounded="8px"
        borderWidth={4}
      >
        <Flex
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <Box height="132px">
            <Icon as={analyzeIcon} boxSize={100} />
          </Box>
          <Text mt={2} fontSize="x-large">
            {props.text}
          </Text>
        </Flex>
      </Button>
    </ChakuraLink>
  );
}

function SelectRecording(): ReactElement {
  const { t } = useTranslation();

  const theme = useTheme();
  const backgroundColor = theme.colors.primary["bg_lv1"];

  useEffect(() => {
    document.body.style.backgroundColor = backgroundColor;
    return () => {
      document.body.style.backgroundColor = "white";
    };
  }, [backgroundColor]);

  const { imageSrc } = useContext(ImageContext);

  return (
    <Layout
      height="100%"
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
    >
      <Layout.Title showBackToHomeButton fontSize="24px">
        {t("AnalysisSelection.title")}
      </Layout.Title>
      <Flex
        direction="column"
        flexGrow={1}
        justifyContent="center"
        alignItems="center"
      >
        <Box flexGrow={0.2} />
        <CreateImageButton
          linkTo="../recording"
          imgUrl={imageSrc?.images["AnalyzeVoiceImg"] ?? AnalyzeVoiceImgUrl}
          text={t("AnalysisSelection.voice")}
        />
        <Box minHeight="20px" flexGrow={0.2} />
        <CreateImageButton
          linkTo="../recording-file"
          imgUrl={imageSrc?.images["AnalyzeFileImg"] ?? AnalyzeFileImgUrl}
          text={t("AnalysisSelection.file")}
        />
        <Box flexGrow={1} />
      </Flex>
    </Layout>
  );
}

export function ProtectedSelectRecording(): ReactElement {
  const [questionnaire] = useAtom(questionnaireAtom);

  if (!SKIP_QUESTIONNAIRES && questionnaire.state === "idle") {
    return <Navigate to={`/${i18n.language}/login`} />;
  }

  if (
    !SKIP_QUESTIONNAIRES &&
    questionnaire.state !== "skipped" &&
    questionnaire.state !== "responded"
  ) {
    return <Navigate to="../questionnaires" replace />;
  } else {
    return <SelectRecording />;
  }
}
