import { ReactElement, useEffect, useRef } from "react";
import Chart from "chart.js/auto";
import { ChartData, ChartOptions } from "chart.js";

export function RenderRadarChart({
  radarChartData,
  radarChartOptions,
}: {
  radarChartData: ChartData;
  radarChartOptions: ChartOptions;
}): ReactElement {
  const chartRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    if (chartRef && chartRef.current) {
      const ctx = chartRef.current.getContext("2d");
      if (ctx !== null) {
        const myChart = new Chart(ctx, {
          type: "radar",
          data: radarChartData,
          options: radarChartOptions,
        });

        return () => {
          myChart.destroy();
        };
      }
    }
  }, [chartRef, radarChartData, radarChartOptions]);

  return <canvas ref={chartRef} width="100%" height="100%" />;
}
