import { BaseAnalysisEngine, BaseAnalysisResult } from "./baseAnalysisEngine";
import { ReactElement } from "react";
import { TFunction } from "react-i18next";
import { ResultDashboard } from "../components/molecules/ResultDashboard";
import {
  ApiEngineType,
  ResultDashboardData,
  ResultDiagramIndex,
  ResultMainParam,
  ResultSubParam,
  ResultSupplementaryInfo,
} from "../types";
import { APP_TYPE } from "../environments";

type Emotion = {
  ang: number;
  joy: number;
  clm: number;
  srw: number;
  exc: number;
  wav_filename: string;
  start_time: number;
  end_time: number;
};

export type AnalysisResult = BaseAnalysisResult & {
  utterances: number;
  duration: number;
  num_vitality_data: number;
  vitality: number;
  mental_activity: number;
  mimosys_sub_parameters: {
    vitality_raw: number;
    mental_activity_raw: number;
    mental_activity_variable: number;
    vivacity: number;
    relaxation: number;
    emotions: Emotion[];
  };
};

const DEFAULT_DASHBOARD_MAIN_PARAM: ResultMainParam = {
  title: "Result.Mi1_vitality",
  // border: "solid 3px #EFB6CB",
  value: undefined,
};

const SUB_PARAMS_TITLE_LIST = ["Result.Mi1_vivacity", "Result.Mi1_relaxation"];

const SUPPLEMENTARY_INFO_TITLE_LIST = [
  "Result.analyze_files",
  "Result.analyze_utterances",
];

export class Mi1Engine extends BaseAnalysisEngine<AnalysisResult> {
  _resultCaches: Record<string, AnalysisResult> = {};
  _engineType: ApiEngineType = "mi1";
  _apiPath = "/analysis/mi1";

  private static dashboardDiagramIndex(t: TFunction): ResultDiagramIndex[] {
    return [
      { width: "32px", title: "No" },
      { width: "40%", title: t("Result.table_phrase"), align: "left" },
      { width: "10%", title: t("Result.Mi1_emotion_Joy") },
      { width: "10%", title: t("Result.Mi1_emotion_Ang") },
      { width: "10%", title: t("Result.Mi1_emotion_Srw") },
      { width: "10%", title: t("Result.Mi1_emotion_Clm") },
      { width: "10%", title: t("Result.Mi1_emotion_Exc") },
      { width: "10%", title: "sec" },
    ];
  }

  private static errorDashboardData(t: TFunction): ResultDashboardData {
    return {
      mainParam: DEFAULT_DASHBOARD_MAIN_PARAM,
      subParams: [
        {
          title: SUB_PARAMS_TITLE_LIST[0],
          value: undefined,
        },
        {
          title: SUB_PARAMS_TITLE_LIST[1],
          value: undefined,
        },
      ],
      supplementaryInfo: [
        {
          title: SUPPLEMENTARY_INFO_TITLE_LIST[0],
          value: undefined,
        },
        {
          title: SUPPLEMENTARY_INFO_TITLE_LIST[1],
          value: undefined,
        },
      ],
      diagramIndex: this.dashboardDiagramIndex(t),
      diagramParams: [],
    };
  }

  private static convertToDashboardData(
    result: AnalysisResult,
    t: TFunction
  ): ResultDashboardData {
    const phrases = result.voice_profiles.voices.map((voice) =>
      voice.phrase ? voice.phrase : "Error"
    );

    const diagramParams = BaseAnalysisEngine.generateResultDiagramParams(
      result.mimosys_sub_parameters.emotions,
      (param, recordId, phraseIndex) => [
        { width: "32px", value: recordId, align: "left" },
        { width: "40%", value: phrases[phraseIndex], align: "left" },
        { width: "10%", value: param.joy },
        { width: "10%", value: param.ang },
        { width: "10%", value: param.srw },
        { width: "10%", value: param.clm },
        { width: "10%", value: param.exc },
        {
          width: "10%",
          value: ((param.end_time - param.start_time) / 1000).toFixed(1),
        },
      ]
    );

    const mainParam: ResultMainParam = {
      ...DEFAULT_DASHBOARD_MAIN_PARAM,
      value: Math.floor(result.vitality * 100),
    };

    const subParams: ResultSubParam[] = [
      {
        title: SUB_PARAMS_TITLE_LIST[0],
        value: Math.floor(result.mimosys_sub_parameters.vivacity * 100),
      },
      {
        title: SUB_PARAMS_TITLE_LIST[1],
        value: Math.floor(result.mimosys_sub_parameters.relaxation * 100),
      },
    ];

    const supplementaryInfo: ResultSupplementaryInfo[] = [
      {
        title: SUPPLEMENTARY_INFO_TITLE_LIST[0],
        value: result.voice_profiles.voices.length,
      },
      {
        title: SUPPLEMENTARY_INFO_TITLE_LIST[1],
        value: result.utterances,
      },
    ];

    return {
      mainParam,
      subParams,
      supplementaryInfo,
      diagramIndex: Mi1Engine.dashboardDiagramIndex(t),
      diagramParams,
    };
  }

  async renderResult(
    key: number,
    fileId: string,
    t: TFunction
  ): Promise<ReactElement> {
    const result = await this.getResult(fileId);
    const dashboardData = result
      ? Mi1Engine.convertToDashboardData(result, t)
      : Mi1Engine.errorDashboardData(t);
    return (
      <ResultDashboard
        key={key}
        title={
          APP_TYPE === "ntt"
            ? t("Result.MIMOSYS_analysis_result")
            : t("Result.Mi1_analysis_result")
        }
        result={dashboardData}
      />
    );
  }
}
