import { DysphagiaResultSummary } from "../../types";
import { ReactElement } from "react";
import { Box, Center, Flex, Image, Text, VStack } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

export function DysphagiaResult(props: {
  summary: DysphagiaResultSummary;
}): ReactElement {
  const spacing = props.summary.resultReliabilityMessage ? 5 : 10;
  const { t } = useTranslation();

  return (
    <Flex
      color="black"
      minHeight="120px"
      fontSize="16px"
      marginTop="5%"
      paddingBottom="15%"
    >
      <Center width="100%">
        <VStack spacing={spacing} align="center" marginTop="1em">
          <Image src={props.summary.imgUrl} boxSize="80%" />
          <Box
            display="grid"
            gridTemplateColumns="150px 80px"
            alignItems="center"
            justifyContent="center"
            bg="#FAEAE7"
            gap={4}
            pb={3}
            width="100%"
            borderRadius="10px"
          >
            <Text fontSize="2xl" textAlign="center">
              {t("Result.Dysphagia_Result")}
            </Text>
            <Text fontSize="4xl" color="#F3B3A6" textAlign="center">
              {props.summary.result}
            </Text>
            <Text fontSize="lg" textAlign="center">
              {t("Result.Dysphagia_Score")}{" "}
            </Text>
            <Text fontSize="lg" textAlign="center">
              {props.summary.score}
            </Text>
          </Box>
          {props.summary.resultReliabilityMessage && (
            <Box bg="primary" borderRadius="10px">
              <Text
                paddingRight="20px"
                paddingLeft="20px"
                fontSize="sm"
                textAlign="center"
                color="white"
              >
                {props.summary.resultReliabilityMessage}
              </Text>
            </Box>
          )}
          <Text fontSize="sm" textAlign="center" color="#555555">
            {props.summary.comment}
          </Text>
        </VStack>
      </Center>
    </Flex>
  );
}
