import {
  BaseAnalysisEngine,
  BaseAnalysisResult,
  isOfType,
} from "./baseAnalysisEngine";
import { ReactElement } from "react";
import { TFunction } from "react-i18next";
import { ResultDashboard } from "../components/molecules/ResultDashboard";
import {
  ApiEngineType,
  ResultDashboardData,
  ResultMainParam,
  ResultSubParam,
} from "../types";

export type AnalysisResult = BaseAnalysisResult & {
  np1_result: string;
  np1_score: number;
  np1_missing_info: Record<string, number>;
  np1_sub_parameters: Record<string, object>;
};

export type ConvertResultType = {
  np1_result: string;
  np1_score: string | number;
  ParamA_LPV_H: string | number;
  ParamA_LPV_CPPS: string | number;
  ParamB_LPV_H: string | number;
  ParamB_LPV_CPPS: string | number;
  ParamC_LPV_H: string | number;
  ParamD_LPV_S: string | number;
  ParamD_LPV_H: string | number;
  ParamD_LPV_D: string | number;
  ParamD_LPV_DR: string | number;
  DSTP_PSD: string | number;
  DSTP_PSA: string | number;
  DSTP_PSS: string | number;
  DSTP_DR: string | number;
};

const SUB_PARAMS_TITLE_LIST = ["Result.Dementia_score"];

const DEFAULT_DASHBOARD_MAIN_PARAM: ResultMainParam = {
  title: "Result.Dementia_rank",
  // border: "solid 3px #EFB6CB",
  value: undefined,
};

const ERROR_DASHBOARD_DATA: ResultDashboardData = {
  mainParam: DEFAULT_DASHBOARD_MAIN_PARAM,
  subParams: [
    {
      title: SUB_PARAMS_TITLE_LIST[0],
      value: undefined,
    },
  ],
  supplementaryInfo: [],
  diagramIndex: [],
  diagramParams: [],
};

export function ConvertResult({
  result,
}: {
  result: AnalysisResult;
}): ConvertResultType {
  if (!result) {
    return {
      np1_result: "-",
      np1_score: "-",
      ParamA_LPV_H: "-",
      ParamA_LPV_CPPS: "-",
      ParamB_LPV_H: "-",
      ParamB_LPV_CPPS: "-",
      ParamC_LPV_H: "-",
      ParamD_LPV_S: "-",
      ParamD_LPV_H: "-",
      ParamD_LPV_D: "-",
      ParamD_LPV_DR: "-",
      DSTP_PSD: "-",
      DSTP_PSA: "-",
      DSTP_PSS: "-",
      DSTP_DR: "-",
    };
  }
  const np1_score = result.np1_score;
  type SubItem01 = {
    ParamA?: {
      LVP_H?: number;
      LVP_CPPS?: number;
    };
    ParamB?: {
      LVP_H?: number;
      LVP_CPPS?: number;
    };
    ParamC?: {
      LVP_H?: number;
    };
    ParamD: {
      LVP_S?: number;
      LVP_H?: number;
      LVP_D?: number;
      LVP_DR: number;
    };
  };

  type SubItem02 = {
    ParamD: {
      DSTP_PSD?: number;
      DSTP_PSA?: number;
      DSTP_PSS?: number;
      DSTP_DR: number;
    };
  };

  const sampleSubItem01: SubItem01 = { ParamD: { LVP_DR: 0 } };
  const sampleSubItem02: SubItem02 = { ParamD: { DSTP_DR: 0 } };

  const isSubItem01 = (item: unknown): item is SubItem01 =>
    isOfType<SubItem01>(item, sampleSubItem01);
  const isSubItem02 = (item: unknown): item is SubItem02 =>
    isOfType<SubItem02>(item, sampleSubItem02);

  const DEFAULT_VALUE = 0.0;
  // 「長母音」
  let ParamA_LPV_H_pre = DEFAULT_VALUE; // 前半HNR
  let ParamA_LPV_CPPS_pre = DEFAULT_VALUE; // 前半CPPS
  let ParamB_LPV_H_pre = DEFAULT_VALUE; // 前半HNR
  let ParamB_LPV_CPPS_pre = DEFAULT_VALUE; // 中盤CPPS
  let ParamC_LPV_H_pre = DEFAULT_VALUE; // 中盤
  let ParamD_LPV_S_pre = DEFAULT_VALUE; // 中盤Shimmer
  let ParamD_LPV_H_pre = DEFAULT_VALUE; // 後半HNR
  let ParamD_LPV_D_pre = DEFAULT_VALUE; // 後半DTW
  let ParamD_LPV_DR_pre = DEFAULT_VALUE; // 後半Duration

  // 「パタカ」
  let DSTP_PSD_pre = DEFAULT_VALUE; // Peak SD
  let DSTP_PSA_pre = DEFAULT_VALUE; // Peak Span Ave(発声の速さ)
  let DSTP_PSS_pre = DEFAULT_VALUE; // Peak Span SD(発声リズムの安定性)
  let DSTP_DR_pre = DEFAULT_VALUE; // Duration「パタカ」の長さ

  for (const key in result.np1_sub_parameters) {
    if (Object.prototype.hasOwnProperty.call(result.np1_sub_parameters, key)) {
      const item = result.np1_sub_parameters[key];
      if (isSubItem01(item)) {
        ParamA_LPV_H_pre = item?.ParamA?.LVP_H ?? DEFAULT_VALUE;
        ParamA_LPV_CPPS_pre = item?.ParamA?.LVP_CPPS ?? DEFAULT_VALUE;
        ParamB_LPV_H_pre = item?.ParamB?.LVP_H ?? DEFAULT_VALUE;
        ParamB_LPV_CPPS_pre = item?.ParamB?.LVP_CPPS ?? DEFAULT_VALUE;
        ParamC_LPV_H_pre = item?.ParamC?.LVP_H ?? DEFAULT_VALUE;
        ParamD_LPV_S_pre = item?.ParamD?.LVP_S ?? DEFAULT_VALUE;
        ParamD_LPV_H_pre = item?.ParamD?.LVP_H ?? DEFAULT_VALUE;
        ParamD_LPV_D_pre = item?.ParamD?.LVP_D ?? DEFAULT_VALUE;
        ParamD_LPV_DR_pre = item?.ParamD?.LVP_DR ?? DEFAULT_VALUE;
      } else if (isSubItem02(item)) {
        DSTP_PSD_pre = item?.ParamD?.DSTP_PSD ?? DEFAULT_VALUE;
        DSTP_PSA_pre = item?.ParamD?.DSTP_PSA ?? DEFAULT_VALUE;
        DSTP_PSS_pre = item?.ParamD?.DSTP_PSS ?? DEFAULT_VALUE;
        DSTP_DR_pre = item?.ParamD?.DSTP_DR ?? DEFAULT_VALUE;
      }
    }
  }

  const ParamA_LPV_H = isNaN(ParamA_LPV_H_pre)
    ? "-"
    : ParamA_LPV_H_pre.toFixed(2);
  const ParamA_LPV_CPPS = isNaN(ParamA_LPV_CPPS_pre)
    ? "-"
    : ParamA_LPV_CPPS_pre.toFixed(2);
  const ParamB_LPV_H = isNaN(ParamB_LPV_H_pre)
    ? "-"
    : ParamB_LPV_H_pre.toFixed(2);
  const ParamB_LPV_CPPS = isNaN(ParamB_LPV_CPPS_pre)
    ? "-"
    : ParamB_LPV_CPPS_pre.toFixed(2);
  const ParamC_LPV_H = isNaN(ParamC_LPV_H_pre)
    ? "-"
    : ParamC_LPV_H_pre.toFixed(2);
  const ParamD_LPV_S = isNaN(ParamD_LPV_S_pre)
    ? "-"
    : ParamD_LPV_S_pre.toFixed(4);
  const ParamD_LPV_H = isNaN(ParamD_LPV_H_pre)
    ? "-"
    : ParamD_LPV_H_pre.toFixed(2);
  const ParamD_LPV_D = isNaN(ParamD_LPV_D_pre)
    ? "-"
    : ParamD_LPV_D_pre.toFixed(2);
  const ParamD_LPV_DR = isNaN(ParamD_LPV_DR_pre)
    ? "-"
    : ParamD_LPV_DR_pre.toFixed(1);
  const DSTP_PSD = isNaN(DSTP_PSD_pre) ? "-" : DSTP_PSD_pre.toFixed(2);
  const DSTP_PSA = isNaN(DSTP_PSA_pre) ? "-" : DSTP_PSA_pre.toFixed(2);
  const DSTP_PSS = isNaN(DSTP_PSS_pre) ? "-" : DSTP_PSS_pre.toFixed(2);
  const DSTP_DR = isNaN(DSTP_DR_pre) ? "-" : DSTP_DR_pre.toFixed(1);

  return {
    np1_result: result.np1_result,
    np1_score: np1_score,
    ParamA_LPV_H: ParamA_LPV_H,
    ParamA_LPV_CPPS: ParamA_LPV_CPPS,
    ParamB_LPV_H: ParamB_LPV_H,
    ParamB_LPV_CPPS: ParamB_LPV_CPPS,
    ParamC_LPV_H: ParamC_LPV_H,
    ParamD_LPV_S: ParamD_LPV_S,
    ParamD_LPV_H: ParamD_LPV_H,
    ParamD_LPV_D: ParamD_LPV_D,
    ParamD_LPV_DR: ParamD_LPV_DR,
    DSTP_PSD: DSTP_PSD,
    DSTP_PSA: DSTP_PSA,
    DSTP_PSS: DSTP_PSS,
    DSTP_DR: DSTP_DR,
  };
}

export class Np1Engine extends BaseAnalysisEngine<AnalysisResult> {
  _resultCaches: Record<string, AnalysisResult> = {};
  _engineType: ApiEngineType = "np1";
  _apiPath = "/analysis/np1";

  private static convertToDashboardData(
    result: AnalysisResult
  ): ResultDashboardData {
    const mainParam: ResultMainParam = {
      ...DEFAULT_DASHBOARD_MAIN_PARAM,
      value: result.np1_result,
    };
    const subParams: ResultSubParam[] = [
      {
        title: SUB_PARAMS_TITLE_LIST[0],
        value: result.np1_score,
      },
    ];

    return {
      mainParam,
      subParams,
      supplementaryInfo: [],
      diagramIndex: [],
      diagramParams: [],
    };
  }

  async renderResult(
    key: number,
    fileId: string,
    t: TFunction
  ): Promise<ReactElement> {
    const result = await this.getResult(fileId);
    const dashboardData = result
      ? Np1Engine.convertToDashboardData(result)
      : ERROR_DASHBOARD_DATA;
    return (
      <ResultDashboard
        key={key}
        title={t("Result.Dementia_analysis_result") + "(NP-1)"}
        result={dashboardData}
      />
    );
  }
}
