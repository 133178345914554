import { FormEvent, ReactElement, useState } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { Layout } from "../../components/atoms/Layout";
import { PasswordInput } from "../../components/atoms/AppInputs";
import { useQuery } from "@tanstack/react-query";
import { appAxios } from "../../utils/appAxios";
import {
  Alert,
  AlertIcon,
  Button,
  Center,
  Spinner,
  useToast,
  VStack,
} from "@chakra-ui/react";
import axios from "axios";
import { useToastMessage } from "../../hooks/useToastMessage";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { ACCOUNT_KEYS } from "../../utils/queryKeyFactory";
import { BannerImage } from "../../components/atoms/BannerImage";

function NewPasswordForm(props: { resetId: string }): ReactElement {
  const { t, i18n } = useTranslation();
  const toastError = useToastMessage();
  const toast = useToast();
  const navigate = useNavigate();
  const [newPassword, setNewPassword] = useState("");
  const [confirmationPassword, setConfirmationPassword] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const onSubmit = async (e: FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      await appAxios.put("/account/password/reset", {
        reset_id: props.resetId,
        new_password: newPassword,
      });
      toast({
        status: "success",
        title: t("PasswordReset.NewPassword.success"),
      });
      navigate(`/${i18n.language}/login`);
    } catch (e) {
      console.error("PasswordReset Error!", e);
      toastError(e);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <form onSubmit={onSubmit}>
      <VStack spacing={8} mt={8}>
        <PasswordInput
          input={{
            placeholder: t("PasswordReset.NewPassword.input"),
            value: newPassword,
            onChange: (e) => setNewPassword(e.target.value),
          }}
        />
        <PasswordInput
          input={{
            placeholder: t("PasswordReset.NewPassword.confirm"),
            value: confirmationPassword,
            onChange: (e) => setConfirmationPassword(e.target.value),
          }}
        />
        <Button
          type="submit"
          variant="btn_primary"
          isLoading={isSubmitting}
          disabled={!newPassword || newPassword !== confirmationPassword}
          w="full"
        >
          {t("PasswordReset.NewPassword.submit")}
        </Button>
      </VStack>
    </form>
  );
}

type ValidationApiResponse = {
  status: boolean;
  expired_at: string;
};

export function ResetId(): ReactElement {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const toastError = useToastMessage();
  const { resetId } = useParams();

  const { data, isLoading, isError, error } = useQuery(
    ACCOUNT_KEYS.password.reset(resetId || ""),
    async () => {
      const { data } = await appAxios.get<ValidationApiResponse>(
        `/account/password/reset/${resetId}`
      );
      return data;
    }
  );

  if (!resetId) {
    return <Navigate to="/404" />;
  }

  if (isLoading) {
    return (
      <Layout>
        <Center>
          <Spinner />
        </Center>
      </Layout>
    );
  }

  if (isError) {
    if (axios.isAxiosError(error) && error?.response?.status === 404) {
      navigate("/404");
    } else {
      toastError(error);
    }
  }
  if (!data) {
    return (
      <Layout>
        <Alert status="error">
          <AlertIcon />
          Error! {(axios.isAxiosError(error) && error.message) || ""}
        </Alert>
      </Layout>
    );
  }

  const now = dayjs();
  const expiredAt = dayjs(data.expired_at);
  const isExpired = now >= expiredAt;

  return (
    <Layout>
      <BannerImage />
      <Layout.Title>{t("PasswordReset.NewPassword.title")}</Layout.Title>
      {isExpired || !data.status ? (
        <Alert status="error">
          <AlertIcon />
          {t("PasswordReset.NewPassword.invalid")}
        </Alert>
      ) : (
        <NewPasswordForm resetId={resetId} />
      )}
    </Layout>
  );
}
