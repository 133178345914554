import { theme_light } from "./common_light";

export const theme_ntt_light = {
  ...theme_light,
  primary: {
    theme_lv1: "#113574", // テーマカラー1
    theme_lv2: "#9CCCF6", // テーマカラー2
    theme_lv3: "#CCE9FF", // テーマカラー3
    accent: "#F34824", // アクセントカラー（ボリュームゲージの最大、ボリューム閾値）
    bg_lv1: "#F8FCFF", // 背景色1
  },
};
