import { FallbackProps } from "react-error-boundary";
import { ReactElement } from "react";
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Button,
  Container,
  Heading,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

export function ErrorFallback({
  error,
  resetErrorBoundary,
}: FallbackProps): ReactElement {
  const { t } = useTranslation();

  return (
    <Container py={12}>
      <Heading as="h1" textAlign="center" mb={5}>
        Unexpected Error
      </Heading>

      <Alert
        status="error"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        py={8}
      >
        <AlertIcon boxSize="40px" mr={0} />
        <AlertTitle mt={4} mb={1} fontSize="lg">
          {error.name}
        </AlertTitle>
        <AlertDescription>{error.message}</AlertDescription>
      </Alert>

      <Button
        width="full"
        variant="btn_primary"
        my={10}
        onClick={resetErrorBoundary}
      >
        {t("ErrorFallback.reset")}
      </Button>
    </Container>
  );
}
