import Dexie, { IndexableType, PromiseExtended, Table } from "dexie";
import crypto from "crypto-js";
import { SelectionLoginUser } from "../types";
import { useCallback } from "react";
import { useToastMessage } from "../hooks/useToastMessage";
import { useTranslation } from "react-i18next";
import CryptoJS from "crypto-js/core";

const AES_KEY = "Pnt44oFab/apIFWZVrJ3jkWnBcwhfOmD";

function encrypt(text: string): string {
  return crypto.AES.encrypt(text, AES_KEY).toString();
}

function decrypt(encryptedText: string): string {
  return crypto.AES.decrypt(encryptedText, AES_KEY).toString(crypto.enc.Utf8);
}

function weakEncrypt(text: string): string {
  const hash = CryptoJS.SHA256(AES_KEY);
  return crypto.AES.encrypt(text, hash, {
    mode: CryptoJS.mode.ECB,
  }).toString();
}

function weakDecrypt(encryptedText: string): string {
  const hash = CryptoJS.SHA256(AES_KEY);
  return crypto.AES.decrypt(encryptedText, hash, {
    mode: CryptoJS.mode.ECB,
  }).toString(crypto.enc.Utf8);
}

type DbUser = {
  id?: number;
  wfeEmail: string;
  fePassword: string;
  nickname: string;
  color: string;
};

function fromAppUser(user: SelectionLoginUser): DbUser {
  const wfeEmail = weakEncrypt(user.email);
  const fePassword = encrypt(user.password);
  return {
    nickname: user.nickname,
    color: user.color,
    wfeEmail,
    fePassword,
  };
}

function intoAppUser(dbUser: DbUser): SelectionLoginUser {
  const email = weakDecrypt(dbUser.wfeEmail);
  const password = decrypt(dbUser.fePassword);
  return {
    id: dbUser.id,
    nickname: dbUser.nickname,
    color: dbUser.color,
    email,
    password,
  };
}

class ClientDb extends Dexie {
  users!: Table<DbUser>;

  constructor() {
    super("app-db");
    this.version(1).stores({
      users: "++id, nickname, &wfeEmail",
    });
  }
}

export class DB {
  private static _db = new ClientDb();

  static addUser(user: SelectionLoginUser): PromiseExtended<IndexableType> {
    const dbUser = fromAppUser(user);
    return this._db.users.add(dbUser);
  }

  static updateUser(
    id: number,
    user: SelectionLoginUser
  ): PromiseExtended<number> {
    const dbUser = fromAppUser(user);
    return this._db.users.update(id, dbUser);
  }

  static deleteUser(id: number): PromiseExtended<number> {
    return this._db.users.where({ id: id }).delete();
  }

  static loadAllUsers(): PromiseExtended<SelectionLoginUser[]> {
    return this._db.users
      .toArray()
      .then((users) => users.map((user) => intoAppUser(user)));
  }

  static loadUserByEmail(
    email: string
  ): PromiseExtended<SelectionLoginUser | undefined> {
    const wfeEmail = weakEncrypt(email);
    return this._db.users
      .where({ wfeEmail })
      .first()
      .then((dbUser) => (dbUser ? intoAppUser(dbUser) : undefined));
  }
}

type AddUserToDB = (user: SelectionLoginUser) => Promise<boolean>;
export function useAddUserToDb(): AddUserToDB {
  const toast = useToastMessage();
  const { t } = useTranslation();

  return useCallback(
    async (user) => {
      const savedUser = await DB.loadUserByEmail(user.email);
      if (savedUser) {
        toast(t("Waring.db.userIsAlreadyExist"), "warning");
        return false;
      } else {
        await DB.addUser(user);
        return true;
      }
    },
    [t, toast]
  );
}
