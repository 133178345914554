import { ReactElement, useEffect, useRef, useState } from "react";
import { Navigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Box,
  Button,
  Center,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  Text,
  VStack,
  useTheme,
} from "@chakra-ui/react";
import { Bars, RotatingLines } from "react-loader-spinner";
import { questionnaireAtom } from "../../../store";
import { Layout } from "../../../components/atoms/Layout";
import { useAtom } from "jotai";
import { AnalysisState, RecordedData } from "../../../types";
import { useAssertEngineTypeFromPathParam } from "../../../utils/selectAnalysisEngine";
import allPhrases from "../../../assets/json/phrases.json";
import { SKIP_QUESTIONNAIRES } from "../../../environments";
import { FileUploader } from "../../../components/molecules/FileUploader";
import { useAnalyzeVoice } from "../../../hooks/useAnalyzeVoice";
import { RawPhraseInfo } from "./recording";

export function RecordingFile(): ReactElement {
  const { t } = useTranslation();
  const engineType = useAssertEngineTypeFromPathParam();
  const analyzeVoice = useAnalyzeVoice(engineType);
  const [questionnaire] = useAtom(questionnaireAtom);
  const requestIdRef = useRef<null | string>(null);
  const [analysisStatus, setAnalysisStatus] = useState<AnalysisState>("IDLE");
  const [allRecordedData, setAllRecordedData] = useState<RecordedData[]>([]);
  const minRecordedPhrase = allPhrases[engineType].minRecordedPhrase;
  const phraseList: RawPhraseInfo[] = allPhrases[engineType].phrases;
  const [dataMap, setDataMap] = useState<{ [key: number]: RecordedData }>({});
  const handleUploadComplete = (id: number, data: RecordedData): void => {
    setDataMap((prevMap) => ({ ...prevMap, [id]: data }));
    setAllRecordedData(Object.values({ ...dataMap, [id]: data }));
  };

  const theme = useTheme();
  const strokeColor = theme.colors.primary["theme_lv1"];
  const backgroundColor = theme.colors.primary["bg_lv1"];

  useEffect(() => {
    document.body.style.backgroundColor = backgroundColor;
    return () => {
      document.body.style.backgroundColor = "white";
    };
  }, [backgroundColor]);

  return (
    <Flex direction="column" minHeight="100vh" color="#555555">
      <Layout flex="1">
        <Layout.Title showBackToHomeButton fontSize="24px">
          {t("FileAnalysis.title")}
        </Layout.Title>
        <VStack spacing={20} align="stretch" width="100%" marginTop="5em">
          {phraseList.map((item, index) => (
            <Box key={index}>
              <Layout.Title>{t(item.titleKey)}</Layout.Title>
              <Text color="text.main_text_lv1" fontSize="xl">
                {item.resultPhraseKey
                  ? t(item.resultPhraseKey)
                  : t(item.phraseKey)}
              </Text>
              <Text fontSize="sm">
                {t(
                  item.uploadKey ? item.uploadKey : "FileAnalysis.upload_note"
                )}
              </Text>
              <Center>
                <FileUploader
                  id={index}
                  phrase={t(item.phraseKey)}
                  {...("co1VoiceType" in item
                    ? { co1VoiceType: item.co1VoiceType }
                    : {})}
                  {...("br1VoiceType" in item
                    ? { br1VoiceType: item.br1VoiceType }
                    : {})}
                  {...("sl1VoiceType" in item
                    ? { sl1VoiceType: item.sl1VoiceType }
                    : {})}
                  {...("co2VoiceType" in item
                    ? { co2VoiceType: item.co2VoiceType }
                    : {})}
                  onUploadComplete={handleUploadComplete}
                />
              </Center>
            </Box>
          ))}
        </VStack>
        {/* 解析中画面 */}
        <Modal
          size="full"
          onClose={() => undefined}
          isOpen={analysisStatus !== "IDLE"}
          isCentered
        >
          <ModalContent>
            <ModalBody display="flex" alignItems="center">
              {analysisStatus === "UPLOADING" ? (
                <VStack margin="auto">
                  <RotatingLines width="100" strokeColor={strokeColor} />
                  <Text color={strokeColor} fontSize="2xl" textAlign="center">
                    Uploading
                  </Text>
                </VStack>
              ) : (
                <VStack margin="auto">
                  <Bars width="100" color={strokeColor} />
                  <Text color={strokeColor} fontSize="2xl" textAlign="center">
                    Analyzing
                  </Text>
                </VStack>
              )}
            </ModalBody>
          </ModalContent>
        </Modal>
      </Layout>
      <Layout as="footer">
        <Button
          variant="btn_primary"
          width="full"
          disabled={allRecordedData.length < minRecordedPhrase}
          onClick={() =>
            analyzeVoice(
              allRecordedData,
              questionnaire,
              requestIdRef,
              setAnalysisStatus
            )
          }
        >
          {t("Recording.startAnalysis")}
        </Button>
      </Layout>
    </Flex>
  );
}

export function ProtectedRecordingFile(): ReactElement {
  const [questionnaire] = useAtom(questionnaireAtom);

  if (
    !SKIP_QUESTIONNAIRES &&
    questionnaire.state !== "skipped" &&
    questionnaire.state !== "responded"
  ) {
    return <Navigate to="../questionnaires" replace />;
  } else {
    return <RecordingFile />;
  }
}
