import { BaseAnalysisEngine, BaseAnalysisResult } from "./baseAnalysisEngine";
import { ReactElement } from "react";
import { TFunction } from "react-i18next";
import {
  ApiEngineType,
  DysphagiaResultSummary,
  ResultSupplementaryInfo,
} from "../types";
import { DysphagiaResultCard } from "../components/molecules/DysphagiaResultCard";

import dysphagiaResultAUrl from "/src/assets/images/dysphagia/dysphagiaResultA.png";
import dysphagiaResultBUrl from "/src/assets/images/dysphagia/dysphagiaResultB.png";
import dysphagiaResultCUrl from "/src/assets/images/dysphagia/dysphagiaResultC.png";

type DysphagiaDetail = {
  fname1: string;
  fname2?: string;
  operation?: string;
  algo?: string;
  val?: number;
};

type AnalysisResult = BaseAnalysisResult & {
  dysphagia_assessment: string;
  dysphagia_score: number;
  dysphagia_number_of_votes: {
    he: number;
    mild: number;
    mod: number;
  };
  dysphagia_missing_info: Record<string, number>;
  dysphagia_sub_parameters: Record<string, DysphagiaDetail[]>;
};

export class DysphagiaEngine extends BaseAnalysisEngine<AnalysisResult> {
  _resultCaches: Record<string, AnalysisResult> = {};
  _engineType: ApiEngineType = "dysphagia";
  _apiPath = "/analysis/dysphagia";

  private static errorResultSummary(t: TFunction): DysphagiaResultSummary {
    return {
      supplementaryInfo: [
        {
          title: "Result.Dysphagia_files",
          value: undefined,
        },
        {
          title: "Result.Dysphagia_utterances",
          value: undefined,
        },
      ],
      imgUrl: "",
      result: "Unknown",
      score: "Unknown",
      comment: t("Result.Dysphagia_analysis_result_Comment" + "Unknown"),
    };
  }

  private static convertToSummary(
    result: AnalysisResult,
    t: TFunction<"translation">
  ): DysphagiaResultSummary {
    //嚥下エンジンの場合、発話区間検出がないので録音数と発話数は同じになる
    const supplementaryInfo: ResultSupplementaryInfo[] = [
      {
        title: "Result.Dysphagia_files",
        value: result.voice_profiles.voices.length,
      },
      {
        title: "Result.Dysphagia_utterances",
        value: result.voice_profiles.voices.length,
      },
    ];

    const dysphagiaAssessment = result.dysphagia_assessment;
    let imgUrl = "";
    if (dysphagiaAssessment === "A") {
      imgUrl = dysphagiaResultAUrl;
    } else if (dysphagiaAssessment === "B") {
      imgUrl = dysphagiaResultBUrl;
    } else if (dysphagiaAssessment === "C") {
      imgUrl = dysphagiaResultCUrl;
    }

    const resultReliabilityMessage =
      Object.keys(result.dysphagia_missing_info).length !== 0
        ? t("Result.Dysphagia_reliability")
        : undefined;

    const dysphagiaScore = String(Math.round(result.dysphagia_score * 100));

    return {
      supplementaryInfo,
      imgUrl,
      result: dysphagiaAssessment,
      score: dysphagiaScore,
      comment: t(
        "Result.Dysphagia_analysis_result_Comment" + dysphagiaAssessment
      ),
      resultReliabilityMessage,
    };
  }

  async renderResult(
    key: number,
    fileId: string,
    t: TFunction
  ): Promise<ReactElement> {
    const result = await this.getResult(fileId);
    const summary = result
      ? DysphagiaEngine.convertToSummary(result, t)
      : DysphagiaEngine.errorResultSummary(t);
    return (
      <DysphagiaResultCard
        key={key}
        title={t("Result.Dysphagia_analysis_result")}
        summary={summary}
      />
    );
  }
}
