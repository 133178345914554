import { useNavigate } from "react-router-dom";
import { AccessToken, LoginErrorCode } from "../types";
import { useSetSetting } from "../utils/settings";
import { useSaveTokenToStorage } from "../utils/token";
import { useAtom } from "jotai";
import { allEngineEnabledAtom, engineTypesAtom } from "../store";
import { decodeJwt } from "../utils/decodeJwt";
import { checkAllEngineEnabled } from "../utils/checkEnableEngine";
import { getSelectableEngineList } from "../pages/engines";

export function useLoginProcess(
  onInvalidUserInfo: (errorCode: LoginErrorCode) => void
): (token: AccessToken, userId?: string) => void {
  const saveToken = useSaveTokenToStorage();
  const setSettingInfo = useSetSetting();
  const navigate = useNavigate();
  const [, setEngineTypes] = useAtom(engineTypesAtom);
  const [, setAllEngineEnable] = useAtom(allEngineEnabledAtom);

  return (token: AccessToken, userId?: string) => {
    const decodedAccessToken = decodeJwt(token.access_token);
    const engine_types = decodedAccessToken.engineTypes as object;
    const allEnginesEnabled = checkAllEngineEnabled(engine_types);
    const selectableEngineList = getSelectableEngineList(
      engine_types,
      allEnginesEnabled
    );
    if (selectableEngineList.length === 0) {
      onInvalidUserInfo("ENGINE_DISABLED");
      return;
    }
    setEngineTypes(engine_types);
    setAllEngineEnable(allEnginesEnabled);
    saveToken(token);
    if (userId) setSettingInfo(userId);
    navigate("../home");
  };
}
