export const theme_light = {
  common: {
    base: "#FFFFFF",
    calibration_description_bg: "#FFFFFF",
  },
  text: {
    main_text_lv1: "#4D4D4D", // 背景色の上の文字色、文字全般
    sub_text: "#999999", // チャートの軸数値、非アクティブなセレクトボックス背景色
  },
  result: {
    meter_needle: "#333333", // 解析結果の針
    meter_text_lv1: "#FFFFFF", // 解析結果メーターメータの文字色(1,2,8,9)
    meter_text_lv2: "#CCCCCC", // 解析結果メーターメータの文字色(3,4,5,6,7,8)
  },
};
