import {
  BaseAnalysisEngine,
  BaseAnalysisResult,
  isOfType,
} from "./baseAnalysisEngine";
import { ReactElement } from "react";
import { TFunction } from "react-i18next";
import { ResultDashboard } from "../components/molecules/ResultDashboard";
import {
  ApiEngineType,
  ResultDashboardData,
  ResultMainParam,
  ResultSubParam,
} from "../types";

export type AnalysisResult = BaseAnalysisResult & {
  cognitive_assessment: string;
  cognitive_score: number;
  cognitive_class_probabilities: CognitiveClassProbabilities;
  cognitive_sub_parameters: Record<string, object>;
};

type CognitiveClassProbabilities = {
  no: number;
  mci: number;
  dmz: number;
};

export type ConvertResultType = {
  cognitive_assessment: string;
  cognitive_score: string | number;
  no: string | number;
  mci: string | number;
  dmz: string | number;
  ParamA_LVP_S: string | number;
  ParamA_LVP_J: string | number;
  ParamA_LVP_H: string | number;
  ParamB_LVP_S: string | number;
  ParamB_LVP_J: string | number;
  ParamB_LVP_H: string | number;
  ParamC_LVP_S: string | number;
  ParamC_LVP_J: string | number;
  ParamC_LVP_H: string | number;
  ParamD_LVP_S: string | number;
  ParamD_LVP_J: string | number;
  ParamD_LVP_H: string | number;
  ParamD_LVP_D: string | number;
  DSTP_AS: string | number;
  DSTP_PS: string | number;
  DSTP_PSA: string | number;
  DSTP_PSS: string | number;
};

const SUB_PARAMS_TITLE_LIST = [
  "Result.Dementia_score",
  "Result.Dementia_normal",
  "Result.Dementia_MCI",
  "Result.Dementia_DMZ",
];

const DEFAULT_DASHBOARD_MAIN_PARAM: ResultMainParam = {
  title: "Result.Dementia_rank",
  // border: "solid 3px #EFB6CB",
  value: undefined,
};

const ERROR_DASHBOARD_DATA: ResultDashboardData = {
  mainParam: DEFAULT_DASHBOARD_MAIN_PARAM,
  subParams: [
    {
      title: SUB_PARAMS_TITLE_LIST[0],
      value: undefined,
    },
    {
      title: SUB_PARAMS_TITLE_LIST[1],
      value: undefined,
    },
    {
      title: SUB_PARAMS_TITLE_LIST[2],
      value: undefined,
    },
    {
      title: SUB_PARAMS_TITLE_LIST[3],
      value: undefined,
    },
  ],
  supplementaryInfo: [],
  diagramIndex: [],
  diagramParams: [],
};

function decorateSortUndecorate(
  arr1: [string, number][],
  arr2: number[]
): [string, number][] {
  const arrMap: [number, [string, number]][] = arr1.map((item, index) => [
    arr2[index],
    item,
  ]);
  return arrMap.sort(([arg1], [arg2]) => arg2 - arg1).map(([, item]) => item);
}

function arrangeAddFraction(
  cog: CognitiveClassProbabilities
): CognitiveClassProbabilities {
  const no100 = cog.no * 100;
  const mci100 = cog.mci * 100;
  const dmz100 = cog.dmz * 100;

  const noInteger = Math.floor(no100);
  const mciInteger = Math.floor(mci100);
  const dmzInteger = Math.floor(dmz100);
  const total = noInteger + mciInteger + dmzInteger;

  const decimalValues = [
    no100 - noInteger,
    mci100 - mciInteger,
    dmz100 - dmzInteger,
  ];
  const integerValues: [string, number][] = [
    ["no", noInteger],
    ["mci", mciInteger],
    ["dmz", dmzInteger],
  ];
  const sortedValues = decorateSortUndecorate(integerValues, decimalValues);

  for (let i = 0; i < 100 - total; i++) {
    sortedValues[i][1] += 1;
  }

  return Object.fromEntries(sortedValues) as CognitiveClassProbabilities;
}

export function ConvertResult({
  result,
}: {
  result: AnalysisResult;
}): ConvertResultType {
  if (!result) {
    return {
      cognitive_assessment: "-",
      cognitive_score: "-",
      no: "-",
      mci: "-",
      dmz: "-",
      ParamA_LVP_S: "-",
      ParamA_LVP_J: "-",
      ParamA_LVP_H: "-",
      ParamB_LVP_S: "-",
      ParamB_LVP_J: "-",
      ParamB_LVP_H: "-",
      ParamC_LVP_S: "-",
      ParamC_LVP_J: "-",
      ParamC_LVP_H: "-",
      ParamD_LVP_S: "-",
      ParamD_LVP_J: "-",
      ParamD_LVP_H: "-",
      ParamD_LVP_D: "-",
      DSTP_AS: "-",
      DSTP_PS: "-",
      DSTP_PSA: "-",
      DSTP_PSS: "-",
    };
  }
  const cognitive_score = Math.floor(result.cognitive_score * 100);
  const no = Math.floor(result.cognitive_class_probabilities.no * 100);
  const mci = Math.floor(result.cognitive_class_probabilities.mci * 100);
  const dmz = Math.floor(result.cognitive_class_probabilities.dmz * 100);
  type SubItem01 = {
    ParamA?: {
      LVP_S?: number;
      LVP_J?: number;
      LVP_H?: number;
    };
    ParamB?: {
      LVP_S?: number;
      LVP_J?: number;
      LVP_H?: number;
    };
    ParamC?: {
      LVP_S?: number;
      LVP_J?: number;
      LVP_H?: number;
    };
    ParamD: {
      LVP_S?: number;
      LVP_J?: number;
      LVP_H?: number;
      LVP_D: number;
    };
  };

  type SubItem02 = {
    DSTP_AS: number;
    DSTP_PS?: number;
    DSTP_PSA?: number;
    DSTP_PSS?: number;
  };

  // missing_infoがあるとco1_sub_parametersのデータ構造が変わる為、
  // データ型からサブパラメーターの種類を取得する
  const sampleSubItem01: SubItem01 = { ParamD: { LVP_D: 0 } };
  const sampleSubItem02: SubItem02 = { DSTP_AS: 0 };

  const isSubItem01 = (item: unknown): item is SubItem01 =>
    isOfType<SubItem01>(item, sampleSubItem01);
  const isSubItem02 = (item: unknown): item is SubItem02 =>
    isOfType<SubItem02>(item, sampleSubItem02);

  const DEFAULT_VALUE = 0.0;
  // 「長母音」
  let ParamA_LVP_S_pre = DEFAULT_VALUE; // 前半Shimmer
  let ParamA_LVP_J_pre = DEFAULT_VALUE; // 前半Jitter
  let ParamA_LVP_H_pre = DEFAULT_VALUE; // 前半HNR
  let ParamB_LVP_S_pre = DEFAULT_VALUE; // 中盤Shimmer
  let ParamB_LVP_J_pre = DEFAULT_VALUE; // 中盤Jitter
  let ParamB_LVP_H_pre = DEFAULT_VALUE; // 中盤HNR
  let ParamC_LVP_S_pre = DEFAULT_VALUE; // 後半Shimmer
  let ParamC_LVP_J_pre = DEFAULT_VALUE; // 後半Jitter
  let ParamC_LVP_H_pre = DEFAULT_VALUE; // 後半HNR
  let ParamD_LVP_S_pre = DEFAULT_VALUE; // 全体Shimmer
  let ParamD_LVP_J_pre = DEFAULT_VALUE; // 全体Jitter
  let ParamD_LVP_H_pre = DEFAULT_VALUE; // 全体HNR
  let ParamD_LVP_D_pre = DEFAULT_VALUE; // 全体DTW

  // 「パタカ」
  let DSTP_AS_pre = DEFAULT_VALUE; // All Scope
  let DSTP_PS_pre = DEFAULT_VALUE; // Peak SD
  let DSTP_PSA_pre = DEFAULT_VALUE; // 発声の速さ
  let DSTP_PSS_pre = DEFAULT_VALUE; // 発声リズムの安定性

  for (const key in result.cognitive_sub_parameters) {
    if (
      Object.prototype.hasOwnProperty.call(result.cognitive_sub_parameters, key)
    ) {
      const item = result.cognitive_sub_parameters[key];
      if (isSubItem01(item)) {
        ParamA_LVP_S_pre = item?.ParamA?.LVP_S ?? DEFAULT_VALUE;
        ParamA_LVP_J_pre = item?.ParamA?.LVP_J ?? DEFAULT_VALUE;
        ParamA_LVP_H_pre = item?.ParamA?.LVP_H ?? DEFAULT_VALUE;
        ParamB_LVP_S_pre = item?.ParamB?.LVP_S ?? DEFAULT_VALUE;
        ParamB_LVP_J_pre = item?.ParamB?.LVP_J ?? DEFAULT_VALUE;
        ParamB_LVP_H_pre = item?.ParamB?.LVP_H ?? DEFAULT_VALUE;
        ParamC_LVP_S_pre = item?.ParamC?.LVP_S ?? DEFAULT_VALUE;
        ParamC_LVP_J_pre = item?.ParamC?.LVP_J ?? DEFAULT_VALUE;
        ParamC_LVP_H_pre = item?.ParamC?.LVP_H ?? DEFAULT_VALUE;
        ParamD_LVP_S_pre = item?.ParamD?.LVP_S ?? DEFAULT_VALUE;
        ParamD_LVP_J_pre = item?.ParamD?.LVP_J ?? DEFAULT_VALUE;
        ParamD_LVP_H_pre = item?.ParamD?.LVP_H ?? DEFAULT_VALUE;
        ParamD_LVP_D_pre = item?.ParamD?.LVP_D ?? DEFAULT_VALUE;
      } else if (isSubItem02(item)) {
        DSTP_AS_pre = item?.DSTP_AS ?? DEFAULT_VALUE;
        DSTP_PS_pre = item?.DSTP_PS ?? DEFAULT_VALUE;
        DSTP_PSA_pre = item?.DSTP_PSA ?? DEFAULT_VALUE;
        DSTP_PSS_pre = item?.DSTP_PSS ?? DEFAULT_VALUE;
      }
    }
  }

  const ParamA_LVP_S = isNaN(ParamA_LVP_S_pre)
    ? "-"
    : ParamA_LVP_S_pre.toFixed(4);
  const ParamA_LVP_J = isNaN(ParamA_LVP_J_pre)
    ? "-"
    : ParamA_LVP_J_pre.toFixed(4);
  const ParamA_LVP_H = isNaN(ParamA_LVP_H_pre)
    ? "-"
    : ParamA_LVP_H_pre.toFixed(2);
  const ParamB_LVP_S = isNaN(ParamB_LVP_S_pre)
    ? "-"
    : ParamB_LVP_S_pre.toFixed(4);
  const ParamB_LVP_J = isNaN(ParamB_LVP_J_pre)
    ? "-"
    : ParamB_LVP_J_pre.toFixed(4);
  const ParamB_LVP_H = isNaN(ParamB_LVP_H_pre)
    ? "-"
    : ParamB_LVP_H_pre.toFixed(2);
  const ParamC_LVP_S = isNaN(ParamC_LVP_S_pre)
    ? "-"
    : ParamC_LVP_S_pre.toFixed(4);
  const ParamC_LVP_J = isNaN(ParamC_LVP_J_pre)
    ? "-"
    : ParamC_LVP_J_pre.toFixed(4);
  const ParamC_LVP_H = isNaN(ParamC_LVP_H_pre)
    ? "-"
    : ParamC_LVP_H_pre.toFixed(2);
  const ParamD_LVP_S = isNaN(ParamD_LVP_S_pre)
    ? "-"
    : ParamD_LVP_S_pre.toFixed(4);
  const ParamD_LVP_J = isNaN(ParamD_LVP_J_pre)
    ? "-"
    : ParamD_LVP_J_pre.toFixed(4);
  const ParamD_LVP_H = isNaN(ParamD_LVP_H_pre)
    ? "-"
    : ParamD_LVP_H_pre.toFixed(2);
  const ParamD_LVP_D = isNaN(ParamD_LVP_D_pre)
    ? "-"
    : ParamD_LVP_D_pre.toFixed(1);

  const DSTP_AS = isNaN(DSTP_AS_pre) ? "-" : DSTP_AS_pre.toFixed(1);
  const DSTP_PS = isNaN(DSTP_PS_pre) ? "-" : DSTP_PS_pre.toFixed(1);
  const DSTP_PSA = isNaN(DSTP_PSA_pre) ? "-" : DSTP_PSA_pre.toFixed(1);
  const DSTP_PSS = isNaN(DSTP_PSS_pre) ? "-" : DSTP_PSS_pre.toFixed(1);

  return {
    cognitive_assessment: result.cognitive_assessment,
    cognitive_score: cognitive_score,
    no: no,
    mci: mci,
    dmz: dmz,
    ParamA_LVP_S: ParamA_LVP_S,
    ParamA_LVP_J: ParamA_LVP_J,
    ParamA_LVP_H: ParamA_LVP_H,
    ParamB_LVP_S: ParamB_LVP_S,
    ParamB_LVP_J: ParamB_LVP_J,
    ParamB_LVP_H: ParamB_LVP_H,
    ParamC_LVP_S: ParamC_LVP_S,
    ParamC_LVP_J: ParamC_LVP_J,
    ParamC_LVP_H: ParamC_LVP_H,
    ParamD_LVP_S: ParamD_LVP_S,
    ParamD_LVP_J: ParamD_LVP_J,
    ParamD_LVP_H: ParamD_LVP_H,
    ParamD_LVP_D: ParamD_LVP_D,
    DSTP_AS: DSTP_AS,
    DSTP_PS: DSTP_PS,
    DSTP_PSA: DSTP_PSA,
    DSTP_PSS: DSTP_PSS,
  };
}

export class CogEngine extends BaseAnalysisEngine<AnalysisResult> {
  _resultCaches: Record<string, AnalysisResult> = {};
  _engineType: ApiEngineType = "cog";
  _apiPath = "/analysis/cog";

  private static convertToDashboardData(
    result: AnalysisResult
  ): ResultDashboardData {
    const mainParam: ResultMainParam = {
      ...DEFAULT_DASHBOARD_MAIN_PARAM,
      value: result.cognitive_assessment,
    };

    const cogProbabilities = arrangeAddFraction(
      result.cognitive_class_probabilities
    );

    const subParams: ResultSubParam[] = [
      {
        title: SUB_PARAMS_TITLE_LIST[0],
        value: Math.floor(result.cognitive_score * 100),
      },
      {
        title: SUB_PARAMS_TITLE_LIST[1],
        value: cogProbabilities.no,
      },
      {
        title: SUB_PARAMS_TITLE_LIST[2],
        value: cogProbabilities.mci,
      },
      {
        title: SUB_PARAMS_TITLE_LIST[3],
        value: cogProbabilities.dmz,
      },
    ];

    return {
      mainParam,
      subParams,
      supplementaryInfo: [],
      diagramIndex: [],
      diagramParams: [],
    };
  }

  async renderResult(
    key: number,
    fileId: string,
    t: TFunction
  ): Promise<ReactElement> {
    const result = await this.getResult(fileId);
    const dashboardData = result
      ? CogEngine.convertToDashboardData(result)
      : ERROR_DASHBOARD_DATA;
    return (
      <ResultDashboard
        key={key}
        title={t("Result.Dementia_analysis_result") + "(Cog)"}
        result={dashboardData}
      />
    );
  }
}
