import { Button, Center, Heading, Text } from "@chakra-ui/react";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { Link, useSearchParams } from "react-router-dom";
import { Layout } from "../components/atoms/Layout";
import { BannerImage } from "../components/atoms/BannerImage";

export function AccountUnlocked(): ReactElement {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const failed = searchParams.get("result") === "failed";

  return (
    <Layout>
      <BannerImage />
      <Center position="relative" mb={110}>
        <Heading as="h1" color="gray.500" size="md" textAlign="center">
          {t("TemporaryUnlock.title")}
        </Heading>
      </Center>
      {failed ? (
        <Text align="center" color="red" mb={20}>
          {t("TemporaryUnlock.mainMessage.failed")}
        </Text>
      ) : (
        <>
          <Text align="center" mb={10}>
            {t("TemporaryUnlock.mainMessage")}
          </Text>
          <Text fontSize="12px" align="center" mb={20}>
            {t("TemporaryUnlock.subMessage")}
          </Text>
        </>
      )}
      <Button
        as={Link}
        to="../login"
        variant="btn_primary"
        display="flex"
        m="0 10%"
      >
        {t("TemporaryUnlock.login")}
      </Button>
    </Layout>
  );
}
