import { ReactElement, useState } from "react";
import { ColorSwatchButton } from "../atoms/ColorSwatchButton";
import { HStack, StackProps } from "@chakra-ui/react";

type ColorInfo = {
  color: string;
  label: string;
};

type Props = StackProps & {
  colorList: ColorInfo[];
  defaultIndex: number;
  onColorChange?: (index: number) => void;
};

export function ColorPicker(props: Props): ReactElement {
  const { colorList, onColorChange, defaultIndex, ...stackProps } = props;
  const [selectedIndex, setSelectedIndex] = useState(defaultIndex);

  return (
    <HStack {...stackProps}>
      {colorList.map((item, colorIndex) => (
        <ColorSwatchButton
          key={colorIndex}
          color={item.color}
          label={item.label}
          size={10}
          isSelected={selectedIndex === colorIndex}
          onClick={() => {
            setSelectedIndex(colorIndex);
            if (onColorChange) {
              onColorChange(colorIndex);
            }
          }}
        />
      ))}
    </HStack>
  );
}
