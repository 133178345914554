import {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation, TFunction } from "react-i18next";
import allPhrases from "../../../assets/json/phrases.json";
import {
  Box,
  Button,
  ButtonProps,
  Flex,
  Icon,
  Modal,
  ModalBody,
  ModalContent,
  Text,
  useTheme,
  VStack,
} from "@chakra-ui/react";
import { RecordingStepper } from "../../../components/atoms/RecordingStepper";
import {
  IoChevronBack,
  IoChevronForward,
  IoMic,
  IoPlaySkipForwardCircleOutline,
  IoStop,
} from "react-icons/io5";
import { useAtom } from "jotai";
import {
  audioRecorder,
  detectVolumeAtom,
  detectedVolumeByPhraseVisualizerAtom,
  questionnaireAtom,
} from "../../../store";
import { Navigate } from "react-router-dom";
import { Bars, RotatingLines } from "react-loader-spinner";
import { useToastMessage } from "../../../hooks/useToastMessage";
import { Layout } from "../../../components/atoms/Layout";
import {
  useAssertEngineTypeFromPathParam,
  useGetAnalysisEngines,
} from "../../../utils/selectAnalysisEngine";
import {
  DEBUG_AUDIO_PLAYER,
  SKIP_QUESTIONNAIRES,
  STOP_RECORDING_BUFFER,
} from "../../../environments";
import {
  AnalysisState,
  CheckVolumeResult,
  PhraseText,
  RecordedData,
  SoundVisualizerRef,
} from "../../../types";
import { useCheckVolume } from "../../../hooks/useCheckVolume";
import { useAnalyzeVoice } from "../../../hooks/useAnalyzeVoice";
import { DebugAudioPlayer } from "../../../components/atoms/DebugAudioPlayer";
import { PhraseBarSoundVisualizer } from "../../../components/molecules/PhraseBarSoundVisualizer";
import { PhraseCircleSoundVisualizer } from "../../../components/molecules/PhraseCircleSoundVisualizer";
import { ErrorDialog } from "../../../components/molecules/ErrorDialog";
import {
  mimosysVolumeToVolume,
  volumeToMimosysVolume,
} from "../../../utils/calcVolume";
import i18n from "../../../i18n";

const DEFAULT_MAX_SEC_OF_UTTERANCE = 10;
const DEFAULT_RECORDING_REQUIREMENT = false;
const DEFAULT_DETECTLENGTH_MSEC = 170; // 発話検知に必要な連続発話時間
const COMPLETED_PHRASE: RawPhraseInfo = {
  titleKey: "PJ.OralFunctionAnalysis.Completed.Title",
  phraseKey: "PJ.OralFunctionAnalysis.Completed.Phrase",
} as const;

export type RawPhraseInfo = {
  titleKey: string;
  phraseKey: string;
  resultPhraseKey?: string;
  helperTextKey?: string;
  baseTextSize?: string;
  mdTextSize?: string;
  helperTextSize?: string;
  co1VoiceType?: string;
  limitSeconds?: number;
  dysphagiaVoiceTypes?: string;
  br1VoiceType?: string;
  sl1VoiceType?: string;
  isRecordingRequired?: boolean;
  detectVoiceLengthMSec?: number;
  uploadKey?: string;
  co2VoiceType?: string;
};

type PhraseData = {
  phraseText: PhraseText;
  limitSeconds: number;
  co1VoiceType?: string;
  dysphagiaVoiceTypes?: string;
  br1VoiceType?: string;
  sl1VoiceType?: string;
  isRecordingRequired: boolean;
  detectVoiceLengthMSec: number;
  co2VoiceType?: string;
};

type RecordingState =
  | "INITIALIZING"
  | "IDLE"
  | "PREPARING"
  | "RECORDING"
  | "STOPPING"
  | "COMPLETED";
type InitializingState =
  | "UNINITIALIZED"
  | "INITIALIZING"
  | "INITIALIZED"
  | "DESTROYING";

function StepperCommonButton(props: ButtonProps): ReactElement {
  return (
    <Button
      fontSize="14px"
      minHeight="30px"
      variant="btn_secondary"
      size="sm"
      {...props}
    />
  );
}

function Stepper(props: {
  recordingState: RecordingState;
  currentPhraseIndex: number;
  allPhraseSize: number;
  minRecordedPhrase: number;
  allRecordedData: RecordedData[];
  onClickBack?: () => void;
  onClickForward?: () => void;
}): ReactElement {
  const { t } = useTranslation();
  return (
    <Box>
      <Flex justifyContent="space-between" alignItems="center" mb={1}>
        <StepperCommonButton
          disabled={props.recordingState === "RECORDING" || !props.onClickBack}
          onClick={props.onClickBack}
        >
          <Flex>
            <Icon boxSize={4} as={IoChevronBack} />
            <Text mr={1}>{t("Recording.previous")}</Text>
          </Flex>
        </StepperCommonButton>
        <StepperCommonButton
          disabled={
            props.recordingState === "RECORDING" || !props.onClickForward
          }
          onClick={props.onClickForward}
        >
          <Flex>
            <Text ml={1}>{t("Recording.next")}</Text>
            <Icon boxSize={4} as={IoChevronForward} />
          </Flex>
        </StepperCommonButton>
      </Flex>
      <RecordingStepper
        maxProgress={props.allPhraseSize}
        progress={props.currentPhraseIndex}
        allRecordedData={props.allRecordedData}
      />
    </Box>
  );
}

function RecordingButton(props: {
  recordingState: RecordingState;
  onClick: () => void;
}): ReactElement {
  const { t } = useTranslation();
  const height = "80px";

  switch (props.recordingState) {
    case "INITIALIZING":
    case "PREPARING":
    case "IDLE":
      return (
        <Button
          variant="btn_primary"
          width="80%"
          minW="250px"
          height={height}
          margin="0 auto"
          isLoading={props.recordingState !== "IDLE"}
          onClick={props.onClick}
        >
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            width="100%"
            position="relative"
          >
            <Icon
              as={IoMic}
              boxSize={8}
              position="absolute"
              left="50%"
              transform="translateX(-110px)"
            />
            <Text fontSize="xl">{t("Recording.startRecording")}</Text>
          </Box>
        </Button>
      );
    case "STOPPING":
    case "RECORDING":
      return (
        <Button
          variant="btn_primary"
          width="80%"
          minW="250px"
          height={height}
          margin="0 auto"
          isLoading={props.recordingState === "STOPPING"}
          onClick={props.onClick}
        >
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            width="100%"
            position="relative"
          >
            <Icon
              as={IoStop}
              boxSize={8}
              position="absolute"
              left="50%"
              transform="translateX(-110px)"
            />
            <Text fontSize="xl">{t("Recording.stopRecording")}</Text>
          </Box>
        </Button>
      );
    case "COMPLETED":
      return <Box height={height} />;
  }
}

function getCurrentPhraseInfo(
  currentPhraseIndex: number,
  phraseList: RawPhraseInfo[],
  t: TFunction
): PhraseData {
  if (currentPhraseIndex >= phraseList.length) {
    return {
      phraseText: {
        title: t(COMPLETED_PHRASE.titleKey),
        phrase: t(COMPLETED_PHRASE.phraseKey),
        co1VoiceType: COMPLETED_PHRASE.co1VoiceType
          ? COMPLETED_PHRASE.co1VoiceType
          : undefined,
      },
      limitSeconds: DEFAULT_MAX_SEC_OF_UTTERANCE,
      isRecordingRequired: DEFAULT_RECORDING_REQUIREMENT,
      detectVoiceLengthMSec:
        COMPLETED_PHRASE.detectVoiceLengthMSec === undefined
          ? DEFAULT_DETECTLENGTH_MSEC
          : COMPLETED_PHRASE.detectVoiceLengthMSec,
    };
  }

  const phraseInfo = phraseList[currentPhraseIndex];
  const limitSeconds =
    phraseInfo.limitSeconds !== undefined
      ? phraseInfo.limitSeconds
      : DEFAULT_MAX_SEC_OF_UTTERANCE;
  const isRecordingRequired =
    phraseInfo.isRecordingRequired !== undefined
      ? phraseInfo.isRecordingRequired
      : DEFAULT_RECORDING_REQUIREMENT;
  return {
    phraseText: {
      title: t(phraseInfo.titleKey),
      phrase: t(phraseInfo.phraseKey),
      co1VoiceType: phraseInfo.co1VoiceType,
      helperText: phraseInfo.helperTextKey
        ? t(phraseInfo.helperTextKey)
        : undefined,
      baseTextSize: phraseInfo.baseTextSize,
      mdTextSize: phraseInfo.mdTextSize,
      helperTextSize: phraseInfo.helperTextSize,
    },
    limitSeconds,
    co1VoiceType: phraseInfo.co1VoiceType,
    dysphagiaVoiceTypes: phraseInfo.dysphagiaVoiceTypes,
    br1VoiceType: phraseInfo.br1VoiceType,
    sl1VoiceType: phraseInfo.sl1VoiceType,
    co2VoiceType: phraseInfo.co2VoiceType,
    isRecordingRequired,
    detectVoiceLengthMSec:
      phraseInfo.detectVoiceLengthMSec === undefined
        ? DEFAULT_DETECTLENGTH_MSEC
        : phraseInfo.detectVoiceLengthMSec,
  };
}

type StopRecordingFunc = () => Promise<RecordedData | null>;
function Recording(): ReactElement {
  const { t } = useTranslation();
  const toastMessage = useToastMessage();
  const engineType = useAssertEngineTypeFromPathParam();
  const analyzeVoice = useAnalyzeVoice(engineType);
  const [currentPhraseIndex, setCurrentPhraseIndex] = useState(0);
  const [detectVolumeForMimosys] = useAtom(detectVolumeAtom);
  const [detectVolume] = useState(
    mimosysVolumeToVolume(detectVolumeForMimosys)
  );
  const [questionnaire] = useAtom(questionnaireAtom);
  const [initializingState, setInitializingState] =
    useState<InitializingState>("UNINITIALIZED");
  const [stopRecording, setStopRecording] = useState<
    null | "PREPARING" | "STOPPING" | StopRecordingFunc
  >(null);
  const visualizerRef = useRef<SoundVisualizerRef>(null);
  const [allRecordedData] = useState<RecordedData[]>([]);
  const [analysisStatus, setAnalysisStatus] = useState<AnalysisState>("IDLE");
  const checkVolume = useCheckVolume();
  const minRecordedPhrase = allPhrases[engineType].minRecordedPhrase;
  const isReachedToLastItem = allPhrases[engineType].isReachedToLastItem;
  const phraseList = allPhrases[engineType].phrases;
  const requestIdRef = useRef<null | string>(null);
  const analysisEngines = useGetAnalysisEngines(engineType);
  const [errorDialogMessage, setErrorDialogMessage] = useState("");

  const {
    phraseText,
    limitSeconds,
    co1VoiceType,
    dysphagiaVoiceTypes,
    br1VoiceType,
    sl1VoiceType,
    isRecordingRequired,
    detectVoiceLengthMSec,
    co2VoiceType,
  } = useMemo(
    () => getCurrentPhraseInfo(currentPhraseIndex, phraseList, t),
    [currentPhraseIndex, phraseList, t]
  );

  const PhraseVisualizer =
    allPhrases[engineType].phraseVisualizerType === "BAR"
      ? PhraseBarSoundVisualizer
      : PhraseCircleSoundVisualizer;

  const [, setIsDetectedVolume] = useAtom(detectedVolumeByPhraseVisualizerAtom);

  const startRecording = useCallback(async () => {
    if (!audioRecorder) return;
    setStopRecording("PREPARING");
    setIsDetectedVolume(false);
    const updatingCallback = (
      averagePower: number,
      maxPower: number,
      detectVolumeFlag: boolean
    ): void => {
      if (visualizerRef.current) {
        visualizerRef.current.draw(
          averagePower,
          1 === maxPower,
          detectVolumeFlag
        );
      }
    };
    await audioRecorder.startRecording(
      updatingCallback,
      undefined,
      volumeToMimosysVolume(detectVolume),
      detectVoiceLengthMSec
    );
    const stop: StopRecordingFunc = async () => {
      setStopRecording("STOPPING");
      const samplingRate = audioRecorder.samplingRate;
      const data = await audioRecorder.stopRecording({
        stopBufferTime: STOP_RECORDING_BUFFER,
      });
      if (visualizerRef.current) {
        visualizerRef.current.draw(0, false, false);
      }
      const phrase = phraseText.phrase;
      setStopRecording(null);
      if (data === null || samplingRate === undefined) return null;
      return {
        data,
        samplingRate,
        phrase,
        isSkipped: false,
        co1VoiceType,
        dysphagiaVoiceTypes,
        br1VoiceType,
        sl1VoiceType,
        co2VoiceType,
      };
    };
    setStopRecording(() => stop);
  }, [
    co1VoiceType,
    dysphagiaVoiceTypes,
    br1VoiceType,
    sl1VoiceType,
    co2VoiceType,
    detectVolume,
    phraseText,
    detectVoiceLengthMSec,
    setIsDetectedVolume,
  ]);

  const skipRecording = useCallback(() => {
    const samplingRate = 0;
    const data = new Float32Array([]);
    if (visualizerRef.current) {
      visualizerRef.current.draw(0, false, false);
    }
    const phrase = phraseText.phrase;
    const isSkipped = true;
    return {
      data,
      samplingRate,
      phrase,
      isSkipped,
      co1VoiceType,
      dysphagiaVoiceTypes,
      br1VoiceType,
      sl1VoiceType,
      co2VoiceType,
    };
  }, [
    phraseText.phrase,
    co1VoiceType,
    dysphagiaVoiceTypes,
    br1VoiceType,
    sl1VoiceType,
    co2VoiceType,
  ]);

  const stepToNextPhrase = useCallback(
    async (recordedData: RecordedData) => {
      if (!recordedData.isSkipped) {
        const volumeCheckResult: CheckVolumeResult = await checkVolume(
          recordedData.data,
          recordedData.samplingRate,
          detectVolume,
          detectVoiceLengthMSec
        );
        if (volumeCheckResult === "Error.allDataIsSilent") {
          setErrorDialogMessage(volumeCheckResult);
        }
        if (volumeCheckResult !== "OK") {
          return null;
        }
      }
      allRecordedData[currentPhraseIndex] = recordedData;
      if (currentPhraseIndex < phraseList.length) {
        setCurrentPhraseIndex(currentPhraseIndex + 1);
      }
    },
    [
      allRecordedData,
      checkVolume,
      currentPhraseIndex,
      detectVolume,
      phraseList.length,
      detectVoiceLengthMSec,
    ]
  );

  const stepBack = useMemo(() => {
    return currentPhraseIndex <= 0
      ? undefined
      : () => setCurrentPhraseIndex(currentPhraseIndex - 1);
  }, [currentPhraseIndex]);

  const stepForward = useMemo(() => {
    return currentPhraseIndex >= allRecordedData.length
      ? undefined
      : () => setCurrentPhraseIndex(currentPhraseIndex + 1);
  }, [allRecordedData.length, currentPhraseIndex]);

  // 初期化処理
  useEffect(() => {
    if (initializingState === "UNINITIALIZED") {
      for (const engine of analysisEngines) {
        engine.preActivate();
      }
      setInitializingState("INITIALIZING");
      audioRecorder
        .init()
        .then(() => setInitializingState("INITIALIZED"))
        .catch((e) => {
          console.warn(e);
          const messageKey =
            e.name === "NotAllowedError"
              ? "Error.notAllowToUseMicrophone"
              : "Error.toUseMicrophone";
          setErrorDialogMessage(messageKey);
        });
    }
  }, [initializingState, t, toastMessage, analysisEngines]);

  // クリーンアップ
  useEffect(() => {
    return () => {
      requestIdRef.current = null;
      setInitializingState("DESTROYING");
      audioRecorder.destroy().then();
    };
  }, []);

  // 録音タイムアウト設定
  useEffect(() => {
    let timeoutId: number | null = null;
    if (typeof stopRecording === "function") {
      timeoutId = window.setTimeout(async () => {
        const res = await stopRecording();
        if (res !== null) {
          return stepToNextPhrase(res);
        }
      }, limitSeconds * 1000);
    }
    return () => {
      if (timeoutId !== null) {
        clearTimeout(timeoutId);
      }
    };
  }, [limitSeconds, stepToNextPhrase, stopRecording]);

  let recordingState: RecordingState;
  if (initializingState !== "INITIALIZED") {
    recordingState = "INITIALIZING";
  } else if (stopRecording !== null) {
    if (stopRecording === "PREPARING") {
      recordingState = "PREPARING";
    } else if (stopRecording === "STOPPING") {
      recordingState = "STOPPING";
    } else {
      recordingState = "RECORDING";
    }
  } else if (currentPhraseIndex >= phraseList.length) {
    recordingState = "COMPLETED";
  } else {
    recordingState = "IDLE";
  }

  const totalRecordedData = allRecordedData.reduce(
    (total, data) => (data.isSkipped ? total : total + 1),
    0
  );

  const theme = useTheme();
  const statusIndicatorColor = theme.colors.primary["theme_lv1"];
  const backgroundColor = theme.colors.primary["bg_lv1"];

  useEffect(() => {
    document.body.style.backgroundColor = backgroundColor;
    return () => {
      document.body.style.backgroundColor = "white";
    };
  }, [backgroundColor]);

  return (
    <>
      {/* NGダイアログ */}
      <ErrorDialog messageKey={errorDialogMessage} />
      <Layout
        height="100%"
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
      >
        <Layout.Title showBackToHomeButton fontSize="24px">
          {phraseText.title}
        </Layout.Title>
        <Stepper
          recordingState={recordingState}
          allPhraseSize={phraseList.length}
          currentPhraseIndex={currentPhraseIndex}
          minRecordedPhrase={minRecordedPhrase}
          allRecordedData={allRecordedData}
          onClickBack={stepBack}
          onClickForward={stepForward}
        />
        <StepperCommonButton
          disabled={
            isRecordingRequired ||
            recordingState === "RECORDING" ||
            recordingState === "COMPLETED"
          }
          margin="0 0 0 auto"
          onClick={() => {
            const result = skipRecording();
            if (result) {
              return stepToNextPhrase(result);
            } else {
              return setErrorDialogMessage("Error.allDataIsSilent");
            }
          }}
        >
          <Flex>
            <Text ml={1}>{t("Recording.skip")}</Text>
            <Icon boxSize={4} as={IoPlaySkipForwardCircleOutline} />
          </Flex>
        </StepperCommonButton>
        <PhraseVisualizer ref={visualizerRef} currentPhrase={phraseText} />
        <RecordingButton
          recordingState={recordingState}
          onClick={
            typeof stopRecording === "function"
              ? () => {
                  stopRecording().then((result) => {
                    if (result) {
                      return stepToNextPhrase(result);
                    } else {
                      setErrorDialogMessage("Error.allDataIsSilent");
                    }
                  });
                }
              : startRecording
          }
        />
        <Button
          variant="btn_primary"
          width="full"
          mt={2}
          isLoading={analysisStatus !== "IDLE"}
          disabled={
            (isReachedToLastItem &&
              allRecordedData.length !== phraseList.length) ||
            totalRecordedData < minRecordedPhrase ||
            recordingState === "RECORDING"
          }
          onClick={() =>
            analyzeVoice(
              allRecordedData,
              questionnaire,
              requestIdRef,
              setAnalysisStatus
            )
          }
        >
          {t("Recording.startAnalysis")}
        </Button>

        {(import.meta.env.DEV || DEBUG_AUDIO_PLAYER) && (
          <DebugAudioPlayer
            title={audioRecorder.audioWrapperType}
            allRecordedData={allRecordedData}
          />
        )}

        {/* 解析中画面 */}
        <Modal
          size="full"
          onClose={() => undefined}
          isOpen={analysisStatus !== "IDLE"}
          isCentered
        >
          <ModalContent backgroundColor="primary.bg_lv1">
            <ModalBody display="flex" alignItems="center">
              {analysisStatus === "UPLOADING" ? (
                <VStack margin="auto">
                  <RotatingLines
                    width="100"
                    strokeColor={statusIndicatorColor}
                  />
                  <Text
                    color="primary.theme_lv1"
                    fontSize="2xl"
                    textAlign="center"
                  >
                    Uploading
                  </Text>
                </VStack>
              ) : (
                <VStack margin="auto">
                  <Bars width="100" color={statusIndicatorColor} />
                  <Text
                    color="primary.theme_lv1"
                    fontSize="2xl"
                    textAlign="center"
                  >
                    Analyzing
                  </Text>
                </VStack>
              )}
            </ModalBody>
          </ModalContent>
        </Modal>
      </Layout>
    </>
  );
}

export function ProtectedRecording(): ReactElement {
  const [questionnaire] = useAtom(questionnaireAtom);

  if (!SKIP_QUESTIONNAIRES && questionnaire.state === "idle") {
    return <Navigate to={`/${i18n.language}/login`} />;
  }

  if (
    !SKIP_QUESTIONNAIRES &&
    questionnaire.state !== "skipped" &&
    questionnaire.state !== "responded"
  ) {
    return <Navigate to="../questionnaires" replace />;
  } else {
    return <Recording />;
  }
}
