import { ReactElement, useEffect } from "react";
import {
  Navigate,
  Outlet,
  Route,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { Calibration } from "./pages/calibration";
import { Home } from "./pages/home";
import { ProtectedQuestionnaires } from "./pages/engines/[engineType]/questionnaires";
import { ProtectedRecording } from "./pages/engines/[engineType]/recording";
import { ProtectedRecordingFile } from "./pages/engines/[engineType]/recording-file";
import { ProtectedSelectRecording } from "./pages/engines/[engineType]/select-recording";
import { Result } from "./pages/engines/[engineType]/results/[fileId]";
import { NotFound } from "./pages/404";
import { AnalysisError } from "./pages/engines/[engineType]/analysis-error";
import { useTranslation } from "react-i18next";
import { Engines } from "./pages/engines/";
import { EngineType } from "./pages/engines/[engineType]";
import { Login } from "./pages/login";
import { useAtom } from "jotai";
import { accessTokenInfoAtom, employeeInfoAtom } from "./store";
import dayjs from "dayjs";
import { PasswordReset } from "./pages/password-reset";
import { ResetId } from "./pages/password-reset/[resetId]";
import { AccountUnlocked } from "./pages/account-unlocked";
import { isValidLanguageType } from "./types";
import { ImageProvider } from "./components/atoms/ImageProvider";
import { APP_TYPE } from "./environments";
import UIX_FAVICON from "./assets/favicon_uix.ico";
import { SentryRoutes } from "../sentry";
import * as Sentry from "@sentry/react";

// ページ遷移時にスクロール位置をリセットする
function ScrollToTop(): ReactElement | null {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  return null;
}

function ProtectedRoutes(): ReactElement {
  const [tokenInfo] = useAtom(accessTokenInfoAtom);
  const { i18n } = useTranslation();
  const [employeeInfo] = useAtom(employeeInfoAtom);
  const now = dayjs();
  const isValid = tokenInfo && dayjs(tokenInfo.expired_at).isAfter(now);
  if (employeeInfo.employee_id) {
    Sentry.setUser({ email: employeeInfo.employee_id });
  }
  return isValid ? <Outlet /> : <Navigate to={`/${i18n.language}/login`} />;
}

function AppRoot(): ReactElement {
  const { lang } = useParams();
  const { i18n } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    if (isValidLanguageType(lang)) {
      if (i18n.language !== lang) {
        i18n.changeLanguage(lang).then();
      }
    } else {
      navigate("/404", { replace: true });
    }
  }, [i18n, lang, navigate]);

  return <Outlet />;
}

function App(): ReactElement {
  const { i18n } = useTranslation();
  const defaultLanguage = isValidLanguageType(i18n.language)
    ? i18n.language
    : "en";
  const defaultPath = `/${defaultLanguage}/home`;

  // 環境に応じてファビコンを表示
  useEffect(() => {
    if (APP_TYPE === "uix") {
      const link = document.querySelector("link[rel*='icon']");
      if (link instanceof HTMLLinkElement) {
        link.href = UIX_FAVICON;
      }
    }
  }, []);

  return (
    <ImageProvider>
      <ScrollToTop />
      <SentryRoutes>
        <Route path="/" element={<Navigate replace to={defaultPath} />} />
        <Route path=":lang" element={<AppRoot />}>
          <Route index element={<Navigate replace to={defaultPath} />} />
          <Route path="login" element={<Login />} />
          <Route path="account-unlocked" element={<AccountUnlocked />} />
          <Route path="password-reset">
            <Route index element={<PasswordReset />} />
            <Route path=":resetId" element={<ResetId />} />
          </Route>

          <Route element={<ProtectedRoutes />}>
            <Route path="home" element={<Home />} />
            <Route path="calibration" element={<Calibration />} />
            <Route path="engines">
              <Route index element={<Engines />} />
              <Route path=":engineType" element={<EngineType />}>
                <Route index element={<Navigate replace to="/404" />} />
                <Route
                  path="select-recording"
                  element={<ProtectedSelectRecording />}
                />
                <Route
                  path="questionnaires"
                  element={<ProtectedQuestionnaires />}
                />
                <Route path="recording" element={<ProtectedRecording />} />
                <Route
                  path="recording-file"
                  element={<ProtectedRecordingFile />}
                />
                <Route path="analysis-error" element={<AnalysisError />} />
                <Route path="results">
                  <Route index element={<Navigate replace to="/404" />} />
                  <Route path=":fileId" element={<Result />} />
                </Route>
              </Route>
            </Route>
          </Route>
        </Route>
        <Route path="/404" element={<NotFound />} />
        <Route path="*" element={<Navigate replace to="/404" />} />
      </SentryRoutes>
    </ImageProvider>
  );
}

export default App;
