import {
  Box,
  Button,
  Center,
  Heading,
  Input,
  Text,
  useTheme,
} from "@chakra-ui/react";
import { ReactElement, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { SubmitHandler, useForm } from "react-hook-form";
import { appAxios } from "../utils/appAxios";
import { useToastMessage } from "../hooks/useToastMessage";
import { Layout } from "../components/atoms/Layout";
import { BackButton } from "../components/atoms/BackButton";
import { BannerImage } from "../components/atoms/BannerImage";

type PasswordResetFormData = {
  user_id: string;
  language: string;
  app_url: string;
};

type RequestFormProps = {
  onCompleted: () => void;
};

function RequestForm({ onCompleted }: RequestFormProps): ReactElement {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<PasswordResetFormData>();
  const { i18n } = useTranslation();
  const toastMessage = useToastMessage();

  const port = window.location.port ? ":" + window.location.port : "";
  const resetUrl =
    window.location.protocol +
    "//" +
    window.location.hostname +
    port +
    "/" +
    i18n.language +
    "/password-reset/{}";

  const onSubmit: SubmitHandler<PasswordResetFormData> = useCallback(
    async (data) => {
      try {
        await appAxios.post("/account/password/reset", data);
        onCompleted();
      } catch (e) {
        console.error("Login Error!", e);
        toastMessage(e);
      }
    },
    [onCompleted, toastMessage]
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <input hidden {...register("language", { value: i18n.language })} />
      <input hidden {...register("app_url", { value: resetUrl })} />
      <Text fontSize="sm">{t("PasswordReset.description.main")}</Text>
      <br />
      <Text fontSize="sm" whiteSpace="pre-wrap">
        {t("PasswordReset.description.attention")}
      </Text>
      <Center>
        <Input
          type="email"
          required
          {...register("user_id", { required: true })}
          placeholder={t("PasswordReset.emailPlaceholder")}
          my={20}
          rounded="full"
          focusBorderColor="primary"
          width="80%"
          size="lg"
          bg="common.base"
        />
      </Center>
      <Text fontSize="sm">{t("PasswordReset.bottomAttention")}</Text>
      <Button
        variant="btn_primary"
        width="full"
        size="lg"
        my={10}
        type="submit"
        isLoading={isSubmitting}
      >
        {t("PasswordReset.send")}
      </Button>
    </form>
  );
}

function Completed(): ReactElement {
  const { t } = useTranslation();
  return (
    <Box pt={10}>
      <Text align="center" whiteSpace="pre-wrap">
        {t("PasswordReset.completed")}
      </Text>
      <Center>
        <Button
          as={Link}
          to="../../login"
          variant="btn_primary"
          width="70%"
          mt={12}
        >
          {t("PasswordReset.login")}
        </Button>
      </Center>
    </Box>
  );
}

export function PasswordReset(): ReactElement {
  const { t } = useTranslation();
  const [isCompleted, setIsCompleted] = useState(false);

  const theme = useTheme();
  const backgroundColor = theme.colors.primary["bg_lv1"];

  useEffect(() => {
    document.body.style.backgroundColor = backgroundColor;
    return () => {
      document.body.style.backgroundColor = "white";
    };
  }, [backgroundColor]);

  return (
    <Layout>
      <BannerImage />
      <Center position="relative" mb={12}>
        <BackButton />
        <Heading
          as="h1"
          color="text.main_text_lv1"
          size="md"
          textAlign="center"
        >
          {t("PasswordReset.title")}
        </Heading>
      </Center>

      {isCompleted ? (
        <Completed />
      ) : (
        <RequestForm onCompleted={() => setIsCompleted(true)} />
      )}
    </Layout>
  );
}
