import { forwardRef, useEffect, useState } from "react";
import { PhraseText, SoundVisualizerRef } from "../../types";
import { CircleSoundVisualizer } from "../atoms/CircleSoundVisualizer";
import { Box, Center, Text } from "@chakra-ui/react";
import { detectVolumeAtom } from "../../store";
import { useAtom } from "jotai";
import { mimosysVolumeToVolume, volumeToDecibel } from "../../utils/calcVolume";

type PhraseVisualizerProps = {
  currentPhrase: PhraseText;
};

function useResponsiveHeight(): string {
  const [isLandscape, setIsLandscape] = useState(
    window.innerWidth > window.innerHeight
  );
  const [height, setHeight] = useState("40vh");

  useEffect(() => {
    function handleResize(): void {
      setIsLandscape(window.innerWidth > window.innerHeight);

      const vh = window.innerHeight * 0.4;
      const vw = window.innerWidth * 0.4;
      const calculatedHeight = isLandscape ? vw : vh;
      setHeight(Math.min(calculatedHeight, 592) + "px");
    }
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [isLandscape]);
  return height;
}

export const PhraseCircleSoundVisualizer = forwardRef<
  SoundVisualizerRef,
  PhraseVisualizerProps
>((props, ref) => {
  const minHeight = useResponsiveHeight();
  const [detectVolumeForMimosys] = useAtom(detectVolumeAtom);
  const [detectVolume] = useState(
    mimosysVolumeToVolume(detectVolumeForMimosys)
  );
  const micVolume = volumeToDecibel(detectVolume) / 100;
  const underThreshold = Math.min(0.75, micVolume);
  return (
    <Box position="relative" minHeight={minHeight} width="100%">
      <CircleSoundVisualizer
        ref={ref}
        upperThreshold={0.81}
        underThreshold={underThreshold}
        maxValue={1}
        height={minHeight}
        width="100%"
      />
      <Center
        position="absolute"
        top={0}
        w="full"
        h="full"
        flexDirection="column"
        gap={2}
      >
        <Text
          maxWidth="300px"
          fontSize={{
            base: props.currentPhrase.baseTextSize || "2xl",
            md: props.currentPhrase.mdTextSize || "3xl",
          }}
          textAlign="center"
          whiteSpace="pre-wrap"
          color="primary.theme_lv1"
          fontWeight={900}
          lineHeight={1.2}
        >
          {props.currentPhrase.phrase}
        </Text>
        <Text
          maxWidth="300px"
          textAlign="center"
          whiteSpace="pre-wrap"
          fontSize={props.currentPhrase.helperTextSize || "15px"}
        >
          {props.currentPhrase.helperText || ""}
        </Text>
      </Center>
    </Box>
  );
});
PhraseCircleSoundVisualizer.displayName = "PhraseVisualizer";
