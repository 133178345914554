// 音量(0～1)をdB(0～100)に換算
export function volumeToDecibel(volume: number): number {
  // ここから以前の計算式
  // if (volume === 0) return 0;
  // const mimosysVolume = volumeToMimosysVolume(volume);
  // return (Math.log10(mimosysVolume) / Math.log10(2 ** 15)) * 100;
  // ここまで以前の計算式

  // 音量値をデシベル(最大振幅1)に変換し、0～100の範囲にスケーリング
  if (volume < 0.00001) return 0;
  const db = 20 * Math.log10(volume); // -∞ ~ -100 (0.00001で-100)
  return 100 + db;
}

// dB(0～100)を音量(0～1)に換算
export function decibelToVolume(decibel: number): number {
  if (decibel === 0) {
    return 0;
  }
  // ここから以前の計算式
  // const mimosysVolume = Math.pow(10, (decibel / 100) * Math.log10(2 ** 15));
  // return mimosysVolumeToVolume(mimosysVolume);
  // ここまで以前の計算式

  const modifiedDb = decibel - 100;
  return Math.pow(10, modifiedDb / 20);
}

// 音量(0～1)をMimosys用音量(0～32768)に換算
export function volumeToMimosysVolume(volume: number): number {
  return volume * 2 ** 15;
}

// Mimosys用音量(1～32768)を音量(0～1)に換算
export function mimosysVolumeToVolume(volume: number): number {
  return volume / 2 ** 15;
}
