import { useAtom } from "jotai";
import { employeeInfoAtom, EmployeeInfoType } from "../store";
import { useCallback, useRef } from "react";
import { appAxios } from "./appAxios";

type SettingInfo = (employeeId: string) => void;

export function useSetSetting(): SettingInfo {
  const setting = useRef(0);
  const [, setEmployeeInfo] = useAtom(employeeInfoAtom);

  return useCallback(
    async (employeeId) => {
      if (setting.current === 0) {
        setting.current++;
        try {
          const settingResponse = await appAxios.get("/analysis/app/settings", {
            params: { employee_id: employeeId },
          });
          const employeeInfo: EmployeeInfoType = {
            employee_id: settingResponse.data.employee_profile.employee_id,
            company_id: settingResponse.data.employee_profile.company_id,
            employee_name: settingResponse.data.employee_profile.employee_name,
          };
          setEmployeeInfo(employeeInfo);
        } catch {
          console.log("not get settings.");
        }
      }
    },
    [setEmployeeInfo]
  );
}
