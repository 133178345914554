import { ReactElement, createElement } from "react";
import {
  Box,
  Flex,
  Stack,
  Text,
  Progress,
  VStack,
  useTheme,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import mi2mi2Image from "../../assets/images/uix/mi1_mi2.png";
import { AnalysisResult as Mi1Result } from "../../engines/mi1Engine";
import {
  AnalysisResult as Mi2Result,
  ConvertResult as ConvertMi2Result,
} from "../../engines/mi2Engine";
import { ResultDiagramIndex, ResultDiagramParam } from "../../types";
import {
  RenderMainResult,
  RenderUixTable,
  getPhraseNumberByFilename,
} from "./ResultUIX";
import { RenderRadarChart } from "../atoms/RadarChart";
import { ChartData, ChartOptions } from "chart.js";
import { hexToRgba } from "../../utils/colorUtil";
import allPhrases from "../../assets/json/phrases.json";

type VOICE_DATA = {
  wav_filename: string;
  start_time: number;
  end_time: number;
};

function RenderMainContent({
  result,
}: {
  result: Record<string, number | string>;
}): ReactElement {
  const { t } = useTranslation();
  const progressMax = 98; // 100だと目盛と少しずれる
  const ZH_Score = result["zh_score"];
  const CpF_Score = result["cpf_score"];
  return (
    <Box>
      <Flex alignItems="center" justifyContent="space-between">
        <Text fontSize={{ base: "sm", sm: "xl" }}>
          {t("UixResult.Mi1Mi2.title")}
        </Text>
        <Text fontSize={{ base: "sm", sm: "lg" }}>
          <span style={{ marginRight: "20px" }}>{`Z-H : ${ZH_Score}`}</span>
          {`CpF : ${CpF_Score}`}
        </Text>
      </Flex>
      <Flex alignItems="flex-end" mt={1} justifyContent="space-between">
        <Text mt="2" whiteSpace="nowrap" fontSize="sm" mb="-1">
          Z-H
        </Text>
        <VStack align="stretch" width="85%">
          <Flex justifyContent="space-between" mb="-2">
            <Text fontSize="xs">0</Text>
            <Text fontSize="xs">50</Text>
            <Text fontSize="xs">100</Text>
          </Flex>
          <Progress
            value={typeof ZH_Score === "number" ? ZH_Score : 0}
            max={100}
            width={`${progressMax}%`}
          />
        </VStack>
      </Flex>
      <Flex alignItems="flex-end" mt={-1} justifyContent="space-between">
        <Text mt="2" whiteSpace="nowrap" fontSize="sm" mb="-1">
          CpF
        </Text>
        <VStack align="stretch" width="85%">
          <Progress
            value={typeof CpF_Score === "number" ? Number(CpF_Score) : 0}
            max={100}
            width="98%"
          />
        </VStack>
      </Flex>
    </Box>
  );
}

export function RenderResult({
  mi1Result,
  mi2Result,
}: {
  mi1Result: Mi1Result;
  mi2Result: Mi2Result;
}): ReactElement {
  const { t } = useTranslation();

  const convertMi2Result = ConvertMi2Result({ result: mi2Result });

  const tablePhrasesColumns: ResultDiagramIndex[] = [
    { width: "32px", title: "No" },
    { width: "100%", title: t("UixResult.voice_content"), align: "left" },
    { width: "30px", subWidth: "18px", title: t("UixResult.Mi1Mi2.joy") },
    { width: "30px", subWidth: "18px", title: t("UixResult.Mi1Mi2.ang") },
    { width: "30px", subWidth: "18px", title: t("UixResult.Mi1Mi2.srw") },
    { width: "30px", subWidth: "18px", title: t("UixResult.Mi1Mi2.clm") },
    { width: "30px", subWidth: "18px", title: t("UixResult.Mi1Mi2.exc") },
    { width: "40px", subWidth: "32px", title: "Z-H" },
    { width: "40px", subWidth: "32px", title: "CpF" },
    { width: "40px", subWidth: "32px", title: "HST" },
    { width: "40px", subWidth: "32px", title: "ZCR" },
    { width: "40px", subWidth: "32px", title: "sec" },
  ];

  const emotions = mi1Result ? mi1Result.mimosys_sub_parameters.emotions : [];
  const mi2_data_list = mi2Result ? mi2Result.mi2_data : [];
  const tablePhrasesData: ResultDiagramParam[][] = [];
  const uixPhrases = allPhrases["UIX"]["phrases"];

  let voiceDataList: VOICE_DATA[] = [];
  if (mi2_data_list.length > 0) {
    voiceDataList = mi2_data_list;
  } else if (emotions.length > 0) {
    voiceDataList = emotions;
  }

  // 同一ファイル名があるか確認
  const filenameAppearanceCounter: Record<number, number> = {};
  voiceDataList.forEach((voiceData) => {
    const wav_filename = voiceData.wav_filename;
    const phraseIndex = getPhraseNumberByFilename(wav_filename);

    if (!filenameAppearanceCounter[phraseIndex]) {
      filenameAppearanceCounter[phraseIndex] = 1;
    } else {
      filenameAppearanceCounter[phraseIndex]++;
    }
  });

  const filenameCounter: Record<number, number> = {};
  voiceDataList.forEach((voiceData) => {
    const wav_filename = voiceData.wav_filename;
    const phraseIndex = getPhraseNumberByFilename(wav_filename);
    if (!filenameCounter[phraseIndex]) {
      filenameCounter[phraseIndex] = 1;
    } else {
      filenameCounter[phraseIndex]++;
    }

    // 1つのファイルで複数発話ある場合はn-n、それ以外はn
    let No;
    if (filenameAppearanceCounter[phraseIndex] > 1) {
      No = `${phraseIndex + 1}-${filenameCounter[phraseIndex]}`;
    } else {
      No = `${phraseIndex + 1}`;
    }
    const time = ((voiceData.end_time - voiceData.start_time) / 1000).toFixed(
      1
    );
    const emotion = emotions.find(
      (emotion) =>
        emotion.wav_filename === wav_filename &&
        emotion.start_time === voiceData.start_time
    );
    const joy = emotion ? emotion.joy : "-";
    const ang = emotion ? emotion.ang : "-";
    const srw = emotion ? emotion.srw : "-";
    const clm = emotion ? emotion.clm : "-";
    const exc = emotion ? emotion.exc : "-";

    const mi2_data = mi2_data_list.find(
      (mi2) =>
        mi2.wav_filename === wav_filename &&
        mi2.start_time === voiceData.start_time
    );
    const zh_value = mi2_data ? Math.floor(mi2_data.zh_value * 100) : "-";
    const cpf_value = mi2_data ? Math.floor(mi2_data.cpf_value * 100) : "-";
    const hurst_exponent = mi2_data
      ? Math.floor(mi2_data.hurst_exponent * 100)
      : "-";
    const zero_cross_rate = mi2_data
      ? Math.floor(mi2_data.zero_cross_rate * 100)
      : "-";

    const phrase =
      uixPhrases[phraseIndex]["resultPhraseKey"] ??
      uixPhrases[phraseIndex]["phraseKey"];

    tablePhrasesData.push([
      { value: No },
      { value: t(phrase), align: "left" },
      { value: joy },
      { value: ang },
      { value: srw },
      { value: clm },
      { value: exc },
      { value: zh_value },
      { value: cpf_value },
      { value: hurst_exponent },
      { value: zero_cross_rate },
      { value: time },
    ]);
  });
  const totalJoy = emotions.reduce((sum, emotion) => sum + emotion.joy, 0);
  const totalAng = emotions.reduce((sum, emotion) => sum + emotion.ang, 0);
  const totalSrw = emotions.reduce((sum, emotion) => sum + emotion.srw, 0);
  const totalClm = emotions.reduce((sum, emotion) => sum + emotion.clm, 0);
  const totalExc = emotions.reduce((sum, emotion) => sum + emotion.exc, 0);
  const totalZhValue = mi2_data_list.reduce(
    (sum, mi2_data) => sum + mi2_data.zh_value,
    0
  );
  const totalCpfValue = mi2_data_list.reduce(
    (sum, mi2_data) => sum + mi2_data.cpf_value,
    0
  );
  const totalHst = mi2_data_list.reduce(
    (sum, mi2_data) => sum + mi2_data.hurst_exponent,
    0
  );
  const totalZcr = mi2_data_list.reduce(
    (sum, mi2_data) => sum + mi2_data.zero_cross_rate,
    0
  );
  const totalTime = voiceDataList.reduce(
    (sum, voiceData) =>
      sum + (voiceData.end_time - voiceData.start_time) / 1000,
    0
  );

  const averageJoy = parseFloat((totalJoy / emotions.length).toFixed(0));
  const averageAng = parseFloat((totalAng / emotions.length).toFixed(0));
  const averageSrw = parseFloat((totalSrw / emotions.length).toFixed(0));
  const averageClm = parseFloat((totalClm / emotions.length).toFixed(0));
  const averageExc = parseFloat((totalExc / emotions.length).toFixed(0));
  const averageZhValue = parseFloat(
    ((totalZhValue / mi2_data_list.length) * 100).toFixed(0)
  );
  const averageCpfValue = parseFloat(
    ((totalCpfValue / mi2_data_list.length) * 100).toFixed(0)
  );
  const averageHst = parseFloat(
    ((totalHst / mi2_data_list.length) * 100).toFixed(0)
  );
  const averageZcr = parseFloat(
    ((totalZcr / mi2_data_list.length) * 100).toFixed(0)
  );
  const averageTime = parseFloat((totalTime / voiceDataList.length).toFixed(1));

  if (voiceDataList.length > 1) {
    tablePhrasesData.push([
      { value: "" },
      { value: t("UixResult.Mi1Mi2.average"), align: "left" },
      { value: !isNaN(averageJoy) ? averageJoy : "-" },
      { value: !isNaN(averageAng) ? averageAng : "-" },
      { value: !isNaN(averageSrw) ? averageSrw : "-" },
      { value: !isNaN(averageClm) ? averageClm : "-" },
      { value: !isNaN(averageExc) ? averageExc : "-" },
      { value: !isNaN(averageZhValue) ? averageZhValue : "-" },
      { value: !isNaN(averageCpfValue) ? averageCpfValue : "-" },
      { value: !isNaN(averageHst) ? averageHst : "-" },
      { value: !isNaN(averageZcr) ? averageZcr : "-" },
      { value: !isNaN(averageTime) ? averageTime : "-" },
    ]);
  }

  const theme = useTheme();

  const radarChartData: ChartData = {
    labels: [
      t("UixResult.Mi1Mi2.joy"),
      t("UixResult.Mi1Mi2.ang"),
      t("UixResult.Mi1Mi2.srw"),
      t("UixResult.Mi1Mi2.clm"),
      t("UixResult.Mi1Mi2.exc"),
    ],
    datasets: [
      {
        label: "",
        data: [averageJoy, averageAng, averageSrw, averageClm, averageExc],
        backgroundColor: hexToRgba(theme.colors.primary["theme_lv1"], 0.2),
        borderColor: theme.colors.primary["theme_lv1"],
        borderWidth: 1,
      },
    ],
  };
  const radarChartOptions: ChartOptions = {
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      r: {
        min: 0,
        max: 5,
        ticks: {
          stepSize: 1,
          font: {
            // 目盛のフォント
            family: "Noto Sans JP, Oswald",
            size: 14,
            style: "normal",
          },
        },
        angleLines: {
          display: true,
        },
        pointLabels: {
          font: {
            // labelsのフォント
            size: 14,
            family: "Noto Sans JP, Oswald",
            style: "normal",
            weight: "bold",
          },
        },
      },
    },
  };

  return (
    <Stack spacing={2}>
      <RenderMainResult
        imgSrc={mi2mi2Image}
        content={createElement(RenderMainContent, { result: convertMi2Result })}
      />
      <Box bg="white" position="relative">
        <Box position="absolute" left="10px" top="10px" zIndex="1">
          <Text>{t("UixResult.Mi1Mi2.voice_emotional_balance")}</Text>
        </Box>
        <Box width="300px" margin="auto" mt={2}>
          <RenderRadarChart
            radarChartData={radarChartData}
            radarChartOptions={radarChartOptions}
          />
        </Box>
      </Box>
      <RenderUixTable
        diagramIndex={tablePhrasesColumns}
        diagramParams={tablePhrasesData}
      />
    </Stack>
  );
}
