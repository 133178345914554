import { ReactElement } from "react";
import { AxiosResponse } from "axios";
import { TFunction } from "react-i18next";
import { appAxios } from "../utils/appAxios";
import { ApiEngineType, MeanResult, ResultDiagramParam } from "../types";

interface CanGetAverage {
  // 契約者単位で平均値を取得
  getAverage: (companyId: string) => Promise<MeanResult>;
}

export const isCanGetAverage = (object: object): object is CanGetAverage => {
  return "getAverage" in object;
};

export const findMeanByDataPath = (
  data: MeanResult | null | undefined,
  targetDataPath: string
): number | undefined => {
  if (!data) {
    return undefined;
  }
  const result = data.find((item) => item.data_path === targetDataPath);
  return result ? result.mean : undefined;
};

export const isOfType = <T>(item: unknown, sample: T): item is T => {
  if (typeof item !== "object" || item === null) return false;
  if (typeof sample !== "object" || sample === null) return false;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const itemRecord = item as Record<string, any>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const sampleRecord = sample as Record<string, any>;

  for (const key in sampleRecord) {
    if (Object.prototype.hasOwnProperty.call(sampleRecord, key)) {
      if (
        typeof sampleRecord[key] === "object" &&
        sampleRecord[key] !== null &&
        typeof itemRecord[key] === "object" &&
        itemRecord[key] !== null
      ) {
        const subKeysSample = Object.keys(sampleRecord[key]);
        const subKeysItem = Object.keys(itemRecord[key]);

        if (!subKeysSample.some((k) => subKeysItem.includes(k))) {
          return false;
        }
      } else if (!Object.keys(itemRecord).includes(key)) {
        return false;
      }
    }
  }
  return true;
};

export abstract class BaseAnalysisEngine<T> {
  abstract _resultCaches: Record<string, T>;
  abstract _engineType: ApiEngineType;
  abstract _apiPath: string;

  async getResult(fileId: string): Promise<T | null> {
    if (this._resultCaches[fileId]) {
      return this._resultCaches[fileId];
    } else {
      try {
        const response = await appAxios.get(this._apiPath, {
          params: {
            file_id: fileId,
          },
        });
        this._resultCaches[fileId] = response.data;
        return response.data;
      } catch {
        return null;
      }
    }
  }

  async preActivate(): Promise<void> {
    // /analysis/{engineName}を/analysis/pre_activate/{engineName}に置換
    const preActivatePath = this._apiPath.replace(
      "/analysis/",
      "/analysis/pre_activate/"
    );
    await appAxios.post(preActivatePath);
  }

  /**
   * @throws {Error}
   */
  async analyze(
    fileId: string,
    detectVolume: number
  ): Promise<AxiosResponse<T>> {
    const startTime = performance.now();
    const response = await appAxios.post(this._apiPath, {
      file_id: fileId,
      config: { voice_activity_detection: { detect_volume: detectVolume } },
    });
    const endTime = performance.now();
    console.info(
      "[WebDK_API] " +
        this._apiPath +
        " : " +
        (endTime - startTime).toFixed(3) +
        "ms"
    );
    this._resultCaches[fileId] = response.data;
    return response;
  }

  abstract renderResult(
    key: number,
    fileId: string,
    t: TFunction<"translation">
  ): Promise<ReactElement>;

  static generateResultDiagramParams<P>(
    paramList: (P & { wav_filename: string })[],
    convertToRecord: (
      param: P & { wav_filename: string },
      recordId: string,
      phraseIndex: number
    ) => ResultDiagramParam[]
  ): ResultDiagramParam[][] {
    let phraseIndex = -1;
    let utteranceCount = -1;
    let prevFileName: null | string = null;
    return paramList.map((param) => {
      if (prevFileName !== param.wav_filename) {
        phraseIndex += 1;
        utteranceCount = 1;
      } else {
        utteranceCount += 1;
      }

      const phraseDisplayStr = (phraseIndex + 1).toString();
      const recordId = `${phraseDisplayStr}${
        utteranceCount === 1 ? "" : "-" + utteranceCount
      }`;

      prevFileName = param.wav_filename;
      return convertToRecord(param, recordId, phraseIndex);
    });
  }
}

export type AnalysisVoiceInfo = {
  phrase?: string;
  cog_voice_type?: "LONG_VOWEL_A" | "COG_ANSWER" | "REPEAT_PATAKA";
  wav_filename: string;
};

export type BaseAnalysisResult = {
  file_id: string;
  voice_profiles: {
    recorded_at: string;
    app_version?: string;
    rec_type?: number;
    voices: AnalysisVoiceInfo[];
    model?: string;
    os_version_id?: string;
    upload_user_id?: string;
  };
  analyzed_at: string;
  engine_version: string;
};
