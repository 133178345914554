import { BaseAnalysisEngine, BaseAnalysisResult } from "./baseAnalysisEngine";
import { ReactElement } from "react";
import { ResultSl1 } from "../components/molecules/ResultSl1";
import { AnalysisEngineType, ApiEngineType } from "../types";
import { formatApiResponse } from "../utils/formatApiResponse";

export type AnalysisResult = BaseAnalysisResult & {
  decision: string; // 重症度
  sl1_score: number; // SASスコア
  pred_ahi: number; // AHI換算
  sl1_sub_parameters: Record<string, object>;
};

// 2群判定
export type BinaryMlScores = {
  prob1_0HEOrMild?: number; // 正常/軽度
  prob1_1ModOrSev?: number; // 中等度/重度
};

type Prob1Type = {
  HealthyOrMild: string;
  ModerateOrSevere: string;
};

// 4群判定
export type MultiMlScores = {
  sample_freq?: number; // サンプリングレート
  prob2_0HE?: number; // 正常
  prob2_1Mild?: number; // 軽度
  prob2_2Mod?: number; // 中等度
  prob2_3Sev?: number; // 重度
};

type Prob2Type = {
  Healthy: string;
  Mild: string;
  Moderate: string;
  Severe: string;
};

export type ConvertResultType = {
  Decision: string;
  Sl1_score: number | string;
  Pred_ahi: number | string;
  ColA_prob1: Prob1Type;
  ColPA_prob1: Prob1Type;
  ColA_sample_freq: number | string;
  ColA_prob2: Prob2Type;
  ColPA_sample_freq: number | string;
  ColPA_prob2: Prob2Type;
  ColGA_sample_freq: number | string;
  ColGA_prob2: Prob2Type;
  ColRA_sample_freq: number | string;
  ColRA_prob2: Prob2Type;
};

export function ConvertResult({
  result,
}: {
  result: AnalysisResult | null;
}): ConvertResultType {
  const resultParams: ConvertResultType = {
    Decision: "-",
    Sl1_score: "-",
    Pred_ahi: "-",
    ColA_prob1: {
      HealthyOrMild: "-",
      ModerateOrSevere: "-",
    },
    ColPA_prob1: {
      HealthyOrMild: "-",
      ModerateOrSevere: "-",
    },
    ColA_sample_freq: "-",
    ColA_prob2: {
      Healthy: "-",
      Mild: "-",
      Moderate: "-",
      Severe: "-",
    },
    ColPA_sample_freq: "-",
    ColPA_prob2: {
      Healthy: "-",
      Mild: "-",
      Moderate: "-",
      Severe: "-",
    },
    ColGA_sample_freq: "-",
    ColGA_prob2: {
      Healthy: "-",
      Mild: "-",
      Moderate: "-",
      Severe: "-",
    },
    ColRA_sample_freq: "-",
    ColRA_prob2: {
      Healthy: "-",
      Mild: "-",
      Moderate: "-",
      Severe: "-",
    },
  };
  if (!result) {
    return resultParams;
  }
  const Sl1_score = result.sl1_score * 100;

  type DetailsBinary = {
    SIT_colA_opensm?: {
      ml_scores?: BinaryMlScores;
    };
    SIT_colPA_opensm?: {
      ml_scores?: BinaryMlScores;
    };
  };

  type DetailsMulti = {
    SIT_colA_opensm?: {
      sample_freq?: number;
      ml_scores?: MultiMlScores;
    };
    SIT_colPA_opensm?: {
      sample_freq?: number;
      ml_scores?: MultiMlScores;
    };
    SIT_colGA_opensm?: {
      sample_freq?: number;
      ml_scores?: MultiMlScores;
    };
    SIT_colRA_opensm?: {
      sample_freq?: number;
      ml_scores?: MultiMlScores;
    };
  };

  resultParams.Decision = result.decision;
  resultParams.Sl1_score = formatApiResponse(Sl1_score);
  resultParams.Pred_ahi = formatApiResponse(result.pred_ahi);

  for (const key in result.sl1_sub_parameters) {
    if (Object.prototype.hasOwnProperty.call(result.sl1_sub_parameters, key)) {
      const item = result.sl1_sub_parameters[key];
      if (key === "details_binary") {
        const detailsBinaryItem = item as DetailsBinary;
        resultParams.ColA_prob1.HealthyOrMild = formatApiResponse(
          detailsBinaryItem?.SIT_colA_opensm?.ml_scores?.prob1_0HEOrMild,
          10
        );
        resultParams.ColA_prob1.ModerateOrSevere = formatApiResponse(
          detailsBinaryItem?.SIT_colA_opensm?.ml_scores?.prob1_1ModOrSev,
          10
        );
        resultParams.ColPA_prob1.HealthyOrMild = formatApiResponse(
          detailsBinaryItem?.SIT_colPA_opensm?.ml_scores?.prob1_0HEOrMild,
          10
        );
        resultParams.ColPA_prob1.ModerateOrSevere = formatApiResponse(
          detailsBinaryItem?.SIT_colPA_opensm?.ml_scores?.prob1_1ModOrSev,
          10
        );
      } else if (key === "details_multiclass") {
        const detailsMultiItem = item as DetailsMulti;
        resultParams.ColA_sample_freq = formatApiResponse(
          detailsMultiItem?.SIT_colA_opensm?.sample_freq,
          1
        );
        resultParams.ColA_prob2.Healthy = formatApiResponse(
          detailsMultiItem?.SIT_colA_opensm?.ml_scores?.prob2_0HE,
          10
        );
        resultParams.ColA_prob2.Mild = formatApiResponse(
          detailsMultiItem?.SIT_colA_opensm?.ml_scores?.prob2_1Mild,
          10
        );
        resultParams.ColA_prob2.Moderate = formatApiResponse(
          detailsMultiItem?.SIT_colA_opensm?.ml_scores?.prob2_2Mod,
          10
        );
        resultParams.ColA_prob2.Severe = formatApiResponse(
          detailsMultiItem?.SIT_colA_opensm?.ml_scores?.prob2_3Sev,
          10
        );
        resultParams.ColPA_sample_freq = formatApiResponse(
          detailsMultiItem?.SIT_colPA_opensm?.sample_freq,
          1
        );
        resultParams.ColPA_prob2.Healthy = formatApiResponse(
          detailsMultiItem?.SIT_colPA_opensm?.ml_scores?.prob2_0HE,
          10
        );
        resultParams.ColPA_prob2.Mild = formatApiResponse(
          detailsMultiItem?.SIT_colPA_opensm?.ml_scores?.prob2_1Mild,
          10
        );
        resultParams.ColPA_prob2.Moderate = formatApiResponse(
          detailsMultiItem?.SIT_colPA_opensm?.ml_scores?.prob2_2Mod,
          10
        );
        resultParams.ColPA_prob2.Severe = formatApiResponse(
          detailsMultiItem?.SIT_colPA_opensm?.ml_scores?.prob2_3Sev,
          10
        );
        resultParams.ColGA_sample_freq = formatApiResponse(
          detailsMultiItem?.SIT_colGA_opensm?.sample_freq,
          1
        );
        resultParams.ColGA_prob2.Healthy = formatApiResponse(
          detailsMultiItem?.SIT_colGA_opensm?.ml_scores?.prob2_0HE,
          10
        );
        resultParams.ColGA_prob2.Mild = formatApiResponse(
          detailsMultiItem?.SIT_colGA_opensm?.ml_scores?.prob2_1Mild,
          10
        );
        resultParams.ColGA_prob2.Moderate = formatApiResponse(
          detailsMultiItem?.SIT_colGA_opensm?.ml_scores?.prob2_2Mod,
          10
        );
        resultParams.ColGA_prob2.Severe = formatApiResponse(
          detailsMultiItem?.SIT_colGA_opensm?.ml_scores?.prob2_3Sev,
          10
        );
        resultParams.ColRA_sample_freq = formatApiResponse(
          detailsMultiItem?.SIT_colRA_opensm?.sample_freq,
          1
        );
        resultParams.ColRA_prob2.Healthy = formatApiResponse(
          detailsMultiItem?.SIT_colRA_opensm?.ml_scores?.prob2_0HE,
          10
        );
        resultParams.ColRA_prob2.Mild = formatApiResponse(
          detailsMultiItem?.SIT_colRA_opensm?.ml_scores?.prob2_1Mild,
          10
        );
        resultParams.ColRA_prob2.Moderate = formatApiResponse(
          detailsMultiItem?.SIT_colRA_opensm?.ml_scores?.prob2_2Mod,
          10
        );
        resultParams.ColRA_prob2.Severe = formatApiResponse(
          detailsMultiItem?.SIT_colRA_opensm?.ml_scores?.prob2_3Sev,
          10
        );
      }
    }
  }
  console.log("resultParams", resultParams);
  return resultParams;
}

interface EngineProps {
  _analysisType?: AnalysisEngineType | null;
}

export class Sl1Engine extends BaseAnalysisEngine<AnalysisResult> {
  _resultCaches: Record<string, AnalysisResult> = {};
  _engineType: ApiEngineType = "sl1";
  _apiPath = "/analysis/sl1";
  _analysisType: AnalysisEngineType | null = null;

  constructor(props?: EngineProps) {
    super();
    if (props && props._analysisType) {
      this._analysisType = props._analysisType;
    }
  }

  async renderResult(key: number, fileId: string): Promise<ReactElement> {
    const result = await this.getResult(fileId);
    return <ResultSl1 key={key} sl1Result={result} />;
  }
}
