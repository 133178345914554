import invariant from "tiny-invariant";
import baseHomeImageUrl from "/src/assets/images/base/home.svg";
import baseHomeImageCo1Url from "/src/assets/images/base/VOISLOG_Home_Co1.png";
import baseBannerUrl from "/src/assets/images/base/banner.svg";
import sompoHomeImageUrl from "/src/assets/images/sompo/SOMPOCare_Home.png";
import sompoBannerUrl from "/src/assets/images/sompo/SOMPOCare_banner.png";
import uixHomeImageUrl from "/src/assets/images/uix/UIX_Home.png";
import uixBannerUrl from "/src/assets/images/uix/UIX_Banner.png";
import nttHomeImageUrl from "/src/assets/images/ntt/NTT_Home.svg";
import nttBannerUrl from "/src/assets/images/ntt/NTT_Banner.svg";
import {
  AnalysisEngineType,
  AppType,
  isValidAnalysisEngineType,
  TokenEngineType,
} from "./types";
function message(name: string, value: unknown): string {
  return `Environment Variable Error: VITE_${name}(${value}) is invalid!`;
}

const baseUrl = import.meta.env.VITE_BASE_API_URL;
invariant(typeof baseUrl === "string", message("BASE_API_URL", baseUrl));
export const BASE_URL = baseUrl;

const appName = import.meta.env.VITE_APP_NAME;
invariant(typeof appName === "string", message("APP_NAME", appName));
export const APP_NAME = appName;

const validAppTypes: AppType[] = ["base", "sompo", "uix", "ntt"];
export const APP_TYPE: AppType = validAppTypes.includes(
  import.meta.env.VITE_APP_TYPE
)
  ? import.meta.env.VITE_APP_TYPE
  : "base";
export const HOME_IMAGE_URL = (() => {
  if (import.meta.env.VITE_CO1_DEMO_MODE === "true") {
    return baseHomeImageCo1Url;
  } else {
    switch (APP_TYPE) {
      case "base":
        return baseHomeImageUrl;
      case "sompo":
        return sompoHomeImageUrl;
      case "uix":
        return uixHomeImageUrl;
      case "ntt":
        return nttHomeImageUrl;
    }
  }
})();

export const BANNER_IMAGE_URL = (() => {
  switch (APP_TYPE) {
    case "base":
      return baseBannerUrl;
    case "sompo":
      return sompoBannerUrl;
    case "uix":
      return uixBannerUrl;
    case "ntt":
      return nttBannerUrl;
  }
})();

export const HIDE_LANGUAGE_SELECTION =
  import.meta.env.VITE_HIDE_LANGUAGE_SELECTION === "true";

const defaultLanguage = import.meta.env.VITE_DEFAULT_LANGUAGE;
export const DEFAULT_LANGUAGE =
  typeof defaultLanguage !== "string" || defaultLanguage === "unset"
    ? undefined
    : defaultLanguage;

export const SKIP_QUESTIONNAIRES =
  import.meta.env.VITE_SKIP_QUESTIONNAIRES === "true";

export const USE_ANALYSIS_ERROR_PAGE =
  import.meta.env.VITE_USE_ANALYSIS_ERROR_PAGE === "true";

const enabledEngines = import.meta.env.VITE_ENABLED_ENGINES;
invariant(
  typeof enabledEngines === "string",
  message("ENABLED_ENGINES", enabledEngines)
);
export const ENABLED_ENGINES: AnalysisEngineType[] = enabledEngines
  .split("&")
  .flatMap((engine) => (isValidAnalysisEngineType(engine) ? engine : []));

export const ENABLED_TOKEN_ENGINE_TYPES: TokenEngineType[] =
  ENABLED_ENGINES.flatMap((engine) => {
    switch (engine) {
      case "Cognitive":
        return ["cog", "co1", "np1"];
      case "cog":
        return ["cog", "mi2"];
      case "co1":
        return ["co1"];
      case "np1":
        return ["np1"];
      case "Mi1_Mi2":
        return ["mi1", "mi2"];
      case "mi2":
        return ["mi2"];
      case "dysphagia":
        return ["dysphagia"];
      case "br1":
        return ["br1"];
      case "UIX":
        return ["mi1", "mi2", "co1", "br1"];
      case "Co1Demo":
        return ["co1"];
      case "NTT":
        return ["mi1"];
      case "sl1":
        return ["sl1"];
      case "Mi2Demo":
        return ["mi2"];
      case "Co2Demo":
        return ["co2"];
    }
  });

export const DEBUG_AUDIO_PLAYER =
  import.meta.env.VITE_DEBUG_AUDIO_PLAYER === "true";

export const ENABLE_FILE_ANALYSIS =
  import.meta.env.VITE_ENABLE_FILE_ANALYSIS === "true";

export const CO1_DEMO_MODE = import.meta.env.VITE_CO1_DEMO_MODE === "true";

// VITE_STOP_RECORDING_BUFFERに数値が指定されていればその値を使う(0も可)
export const STOP_RECORDING_BUFFER = Number.isNaN(
  parseInt(import.meta.env.VITE_STOP_RECORDING_BUFFER, 10)
)
  ? 650
  : parseInt(import.meta.env.VITE_STOP_RECORDING_BUFFER, 10);
