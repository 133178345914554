import {
  forwardRef,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputGroupProps,
  InputProps,
  InputRightElement,
} from "@chakra-ui/react";
import { IoEyeOffOutline, IoEyeOutline } from "react-icons/io5";
import { useState } from "react";

const DEFAULT_INPUT_PROPS: InputProps = {
  isRequired: true,
  rounded: "full",
  focusBorderColor: "primary.theme_lv1",
  size: "lg",
};

export const IdInput = forwardRef<InputProps, "input">((props, ref) => {
  return (
    <Input
      ref={ref}
      {...DEFAULT_INPUT_PROPS}
      type="email"
      placeholder="ID"
      {...props}
      bg="common.base"
    />
  );
});

type PasswordInputProps = { root?: InputGroupProps; input?: InputProps };
export const PasswordInput = forwardRef<PasswordInputProps, "input">(
  (props, ref) => {
    const [isPasswordShown, setIsPasswordShown] = useState(false);

    return (
      <InputGroup {...props.root}>
        <Input
          ref={ref}
          {...DEFAULT_INPUT_PROPS}
          type={isPasswordShown ? "text" : "password"}
          placeholder="Password"
          pr={16}
          {...props.input}
          bg="common.base"
        />
        <InputRightElement width={16} h="full">
          <IconButton
            size="md"
            variant="ghost"
            aria-label={isPasswordShown ? "hide" : "show"}
            tabIndex={-1}
            onClick={() => setIsPasswordShown(!isPasswordShown)}
            icon={
              isPasswordShown ? (
                <Icon
                  as={IoEyeOutline}
                  boxSize={6}
                  color="text.main_text_lv1"
                />
              ) : (
                <Icon
                  as={IoEyeOffOutline}
                  boxSize={6}
                  color="text.main_text_lv1"
                />
              )
            }
          />
        </InputRightElement>
      </InputGroup>
    );
  }
);

export const TextInput = forwardRef<InputProps, "input">((props, ref) => {
  return <Input ref={ref} {...DEFAULT_INPUT_PROPS} type="text" {...props} />;
});
