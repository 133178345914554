import { ReactElement } from "react";
import { Box, Image, useTheme } from "@chakra-ui/react";
import { APP_TYPE, HOME_IMAGE_URL, APP_NAME } from "../../environments";
import { ReactSVG } from "react-svg";
import { setSVGFillColor } from "../../utils/svgHelper";

export function HomeImage(): ReactElement {
  const theme = useTheme();

  return (
    <>
      {APP_TYPE === "base" && (
        <Box alignItems="center" justifyContent="center" width="full">
          <ReactSVG
            src={HOME_IMAGE_URL}
            beforeInjection={(svg) => {
              svg.removeAttribute("width");
              svg.removeAttribute("height");
              svg.style.width = "100%";
              svg.style.height = "100%";
              const title = document.createElementNS(
                "http://www.w3.org/2000/svg",
                "title"
              );
              title.textContent = APP_NAME;
              svg.prepend(title);
              setSVGFillColor(svg, ".cls-1", theme.colors.primary["theme_lv1"]);
            }}
          />
        </Box>
      )}
      {APP_TYPE !== "base" && (
        <Image
          src={HOME_IMAGE_URL}
          alt={APP_NAME}
          maxW={{ sm: "md", md: "lg" }}
        />
      )}
    </>
  );
}
