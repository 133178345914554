import axios from "axios";
import { TOKEN_INFO_SESSION_STORAGE_KEY } from "../constants";
import { BASE_URL } from "../environments";

export const appAxios = axios.create({ baseURL: BASE_URL });

appAxios.interceptors.request.use(async (config) => {
  const tokenInfoStr = sessionStorage.getItem(TOKEN_INFO_SESSION_STORAGE_KEY);
  const tokenInfo = JSON.parse(tokenInfoStr || "null");
  if (tokenInfo) {
    return {
      ...config,
      headers: {
        Authorization: `Bearer ${tokenInfo.access_token}`,
        ...config.headers,
      },
    };
  } else {
    return config;
  }
});
