import { useTranslation } from "react-i18next";
import { useToastMessage } from "../hooks/useToastMessage";
import {
  AccessToken,
  LoginErrorCode,
  LoginFormData,
  TokenEngineType,
  isObject,
  isValidAppApiError,
} from "../types";
import { AccessTokenInfo, accessTokenInfoAtom } from "../store";
import { useCallback } from "react";
import { useAtom } from "jotai";
import { ENABLED_TOKEN_ENGINE_TYPES } from "../environments";
import { appAxios } from "./appAxios";
import { decodeJwt } from "./decodeJwt";
import axios from "axios";
import { CredentialResponseType } from "../components/molecules/GoogleLoginButton";
import dayjs from "dayjs";

type AccessTokenRequest = {
  user_id: string;
  password: string;
  engine_types: TokenEngineType[];
};

type GetAccessToken = (
  onInvalidUserInfo: (errorCode: LoginErrorCode) => void,
  loginFormData?: LoginFormData,
  googleAuthData?: CredentialResponseType
) => Promise<AccessToken | false>;
export function useGetAccessToken(): GetAccessToken {
  const toastMessage = useToastMessage();
  const { i18n, t } = useTranslation();

  return useCallback(
    async (onInvalidUserInfo, loginFormData, googleAuthData) => {
      try {
        let response;
        if (loginFormData) {
          const accessTokenRequestParam: AccessTokenRequest = {
            user_id: loginFormData.user_id,
            password: loginFormData.password,
            engine_types: ENABLED_TOKEN_ENGINE_TYPES,
          };
          response = await appAxios.post("/token", accessTokenRequestParam);
        } else if (googleAuthData) {
          response = await appAxios.post(
            "/oauth/google/token ",
            googleAuthData
          );
        } else {
          console.error(
            "Error: Either loginFormData or googleAuthData is required."
          );
          return false;
        }
        const decodedAccessToken = decodeJwt(response.data.access_token);

        if (decodedAccessToken.userType !== "employee") {
          toastMessage(t("ApiError.403"));
          return false;
        } else {
          return response.data;
        }
      } catch (e) {
        console.error("Get Access Token Error!", e);
        if (!axios.isAxiosError(e)) {
          toastMessage("Internal Error!");
          return false;
        }

        const errData = e.response?.data;
        if (!isValidAppApiError(errData) || !isObject(errData.detail)) {
          toastMessage(e);
          return false;
        }

        if (errData.detail.type === "auth_error.invalid_input") {
          onInvalidUserInfo("INVALID_TOKEN");
        } else if (
          loginFormData &&
          errData.detail.type === "auth_error.locked_temporary"
        ) {
          toastMessage(e);
          appAxios
            .put("/account/temporary_locked/unlock/request", {
              user_id: loginFormData.user_id,
              app_url: `${location.origin}/${i18n.language}/account-unlocked`,
              language: i18n.language,
            })
            .catch((e) => {
              console.error("Unlock to Temporary Locked Error!", e);
              toastMessage(e);
            });
        } else {
          toastMessage(e);
        }

        return false;
      }
    },
    [i18n.language, t, toastMessage]
  );
}

type SaveTokenToStorage = (data: AccessToken) => void;
export function useSaveTokenToStorage(): SaveTokenToStorage {
  const [, setAccessToken] = useAtom(accessTokenInfoAtom);

  return useCallback(
    (data) => {
      const now = dayjs();
      const tokenInfo: AccessTokenInfo = {
        access_token: data.access_token,
        expired_at: now.add(data.expires_in, "second").toISOString(),
      };
      setAccessToken(tokenInfo);
    },
    [setAccessToken]
  );
}
