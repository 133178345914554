import {
  BaseAnalysisEngine,
  BaseAnalysisResult,
  isOfType,
} from "./baseAnalysisEngine";
import { ReactElement } from "react";
import { TFunction, useTranslation } from "react-i18next";
import { Box, Center, Text, Flex, useTheme } from "@chakra-ui/react";
import {
  ApiEngineType,
  MeanResult,
  ResultDashboardData,
  ResultMainParam,
  ResultSubParam,
} from "../types";
import { AnalysisResultCardLayout } from "../components/atoms/AnalysisResultCardLayout";
import { MainParamBox } from "../components/atoms/DashboardBox";
import { appAxios } from "../utils/appAxios";

export type AnalysisResult = BaseAnalysisResult & {
  BNP_score: number;
  GOKAN_score: number;
  NYHA_prob: number;
  NYHA_PN: string;
  CPA_prob: number;
  CPA_PN: string;
  CON_prob: number;
  CON_PN: string;
  br1_sub_parameters: Record<string, object>;
};

const DEFAULT_DASHBOARD_MAIN_PARAM: ResultMainParam = {
  title: "",
  border: "",
  value: undefined,
};

const ERROR_DASHBOARD_DATA: ResultDashboardData = {
  mainParam: DEFAULT_DASHBOARD_MAIN_PARAM,
  subParams: [],
  supplementaryInfo: [],
  diagramIndex: [],
  diagramParams: [],
};

function SubParamBox({
  title,
  subtitle,
  value,
  range,
  color,
}: {
  title: string;
  subtitle: string;
  value: number | string;
  range: string;
  color?: string;
}): ReactElement {
  const theme = useTheme();
  const borderColor = theme.colors.primary["theme_lv2"];
  return (
    <Flex
      width="80%"
      maxWidth="440px"
      minWidth="320px"
      alignItems="center"
      justifyContent="space-between"
      border={`2px solid ${borderColor}`}
      borderRadius="8px"
      bg="white"
      padding={3}
    >
      <Box alignSelf="flex-start">
        <Text fontSize="18px" fontWeight="bold">
          {title}
        </Text>
        <Text fontSize="14px">{subtitle}</Text>
      </Box>
      <Flex>
        <Text fontSize="24px" fontWeight="bold" color={color}>
          {value}
        </Text>
        <Text fontSize="12px" ml={2} mt={3}>
          {range}
        </Text>
      </Flex>
    </Flex>
  );
}

function PhraseBox({
  phrase,
  length,
  strength,
}: {
  phrase: string;
  length: number | string;
  strength: number | string;
}): ReactElement {
  const { t } = useTranslation();
  const theme = useTheme();
  const borderColor = theme.colors.primary["theme_lv2"];
  return (
    <Box
      width="210px"
      border={`2px solid ${borderColor}`}
      borderRadius="8px"
      bg="white"
      textAlign="center"
      padding={3}
    >
      <Text fontSize="16px" fontWeight="bold">
        {phrase}
      </Text>
      <Center>
        <Box width="120px" textAlign="left">
          <Text fontSize="14px">
            {t("Result.Voice_Length")}: {length}{" "}
            {t("Result.Voice_Length_Second")}
          </Text>
          <Text fontSize="14px">
            {t("Result.Voice_Strength")}: {strength}
          </Text>
        </Box>
      </Center>
    </Box>
  );
}

export function ConvertResult({
  result,
}: {
  result: AnalysisResult;
}): Record<string, number | string> {
  if (!result) {
    return {
      BNP_score: "-",
      GOKAN_score: "-",
      NYHA_prob: "-",
      NYHA_PN: "-",
      CPA_prob: "-",
      CPA_prob_PN: "-",
      CON_prob: "-",
      CON_prob_PN: "-",
      LVP_DR: "-",
      LVP_RM: "-",
      DSTP_DR: "-",
      DSTP_PA: "-",
    };
  }
  // BNP推定値
  const BNP_score = (result.BNP_score * 100).toFixed(1);
  // GKANスコア
  const GOKAN_score = result.GOKAN_score.toFixed(1);
  // NYHAⅡ以上リスク
  const NYHA_prob_pre = (result.NYHA_prob * 100).toFixed(1);
  const NYHA_prob = parseFloat(NYHA_prob_pre) > 100.0 ? 100.0 : NYHA_prob_pre;
  const NYHA_prob_PN = result.NYHA_PN == "Positive" ? "emphasize" : "";

  // 胸水貯留リスク
  const CPA_prob_pre = (result.CPA_prob * 100).toFixed(1);
  const CPA_prob = parseFloat(CPA_prob_pre) > 100.0 ? 100.0 : CPA_prob_pre;
  const CPA_prob_PN = result.CPA_PN == "Positive" ? "emphasize" : "";

  // 肺うっ血リスク
  const CON_prob_pre = (result.CON_prob * 100).toFixed(1);
  const CON_prob = parseFloat(CON_prob_pre) > 100.0 ? 100.0 : CON_prob_pre;
  const CON_prob_PN = result.CON_PN == "Positive" ? "emphasize" : "";

  type SubItem01 = {
    DSTP_AS?: number;
    DSTP_PS?: number;
    DSTP_PA: number;
    DSTP_PSA?: number;
    DSTP_PSS?: number;
    DSTP_DR: number;
  };

  type SubItem02 = {
    ParamA?: {
      LVP_S?: number;
      LVP_J?: number;
      LVP_H?: number;
      LVP_V?: number;
    };
    ParamB?: {
      LVP_S?: number;
      LVP_J?: number;
      LVP_H?: number;
      LVP_V?: number;
    };
    ParamC?: {
      LVP_S?: number;
      LVP_J?: number;
      LVP_H?: number;
      LVP_V?: number;
    };
    ParamD: {
      LVP_CF?: number;
      LVP_HE?: number;
      LVP_S?: number;
      LVP_J?: number;
      LVP_H?: number;
      LVP_V?: number;
      LVP_DR: number;
      LVP_ZR?: number;
      LVP_RM?: number;
    };
  };

  // データ型からサブパラメーターの種類を取得する
  const sampleSubItem01: SubItem01 = { DSTP_DR: 0, DSTP_PA: 0 };
  const sampleSubItem02: SubItem02 = { ParamD: { LVP_DR: 0 } };

  const isSubItem01 = (item: unknown): item is SubItem01 =>
    isOfType<SubItem01>(item, sampleSubItem01);
  const isSubItem02 = (item: unknown): item is SubItem02 =>
    isOfType<SubItem02>(item, sampleSubItem02);

  const DEFAULT_VALUE = 0.0;
  let DSTP_DR_pre = DEFAULT_VALUE;
  let DSTP_PA_pre = DEFAULT_VALUE;
  let LVP_DR_pre = DEFAULT_VALUE;
  let LVP_RM_pre = DEFAULT_VALUE;

  for (const key in result.br1_sub_parameters) {
    if (Object.prototype.hasOwnProperty.call(result.br1_sub_parameters, key)) {
      const item = result.br1_sub_parameters[key];
      if (isSubItem01(item)) {
        DSTP_DR_pre = item.DSTP_DR ?? DEFAULT_VALUE;
        DSTP_PA_pre = item.DSTP_PA ?? DEFAULT_VALUE;
      } else if (isSubItem02(item)) {
        LVP_DR_pre = item.ParamD?.LVP_DR ?? DEFAULT_VALUE;
        LVP_RM_pre = item.ParamD?.LVP_RM ?? DEFAULT_VALUE;
      }
    }
  }

  // 音声解析「あーー」長さ
  const LVP_DR = LVP_DR_pre ? parseFloat(LVP_DR_pre.toFixed(1)) : 0.0;
  // 音声解析「あーー」強さ
  const LVP_RM_pre2 = LVP_RM_pre
    ? Math.round((Math.log(LVP_RM_pre) + 2.759329) * 22.24486 + 45)
    : 0;
  const LVP_RM_pre3 = LVP_RM_pre2 > 100 ? 100 : LVP_RM_pre2;
  const LVP_RM = LVP_RM_pre3 < 0 ? 0 : LVP_RM_pre3;

  // 音声解析「パタカ」長さ
  const DSTP_DR = DSTP_DR_pre ? parseFloat(DSTP_DR_pre.toFixed(1)) : 0.0;
  // 音声解析「パタカ」強さ
  const DSTP_PA_pre2 = DSTP_PA_pre
    ? Math.round((DSTP_PA_pre - 69.12964) * 2.673025 + 45)
    : 0;
  const DSTP_PA_pre3 = DSTP_PA_pre2 > 100 ? 100 : DSTP_PA_pre2;
  const DSTP_PA = DSTP_PA_pre3 < 0 ? 0 : DSTP_PA_pre3;

  return {
    BNP_score: BNP_score,
    GOKAN_score: GOKAN_score,
    NYHA_prob: NYHA_prob,
    NYHA_prob_PN: NYHA_prob_PN,
    CPA_prob: CPA_prob,
    CPA_prob_PN: CPA_prob_PN,
    CON_prob: CON_prob,
    CON_prob_PN: CON_prob_PN,
    LVP_DR: LVP_DR,
    LVP_RM: LVP_RM,
    DSTP_DR: DSTP_DR,
    DSTP_PA: DSTP_PA,
  };
}
// analysis/br1のレスポンスにおける長母音持続時間のパス
export const AnalysisResultDurationPath =
  "br1_sub_parameters.*.ParamD[].LVP_DR";

export class Br1Engine extends BaseAnalysisEngine<AnalysisResult> {
  _resultCaches: Record<string, AnalysisResult> = {};
  _engineType: ApiEngineType = "br1";
  _apiPath = "/analysis/br1";

  async getAverage(companyId?: string): Promise<MeanResult | null> {
    const params = {
      engine: "br1",
      data_path: AnalysisResultDurationPath,
      company_id: companyId,
    };
    try {
      const response = await appAxios.get("/analysis/statistics/mean", {
        params,
      });
      return response.data;
    } catch {
      return null;
    }
  }

  private static convertToDashboardData(
    result: AnalysisResult
  ): ResultDashboardData {
    const phrases = result.voice_profiles.voices.map((voice) =>
      voice.phrase ? voice.phrase : "Error"
    );

    const mainParam: ResultMainParam = {
      ...DEFAULT_DASHBOARD_MAIN_PARAM,
      value: result.BNP_score,
    };

    const convertResult = ConvertResult({ result });

    const subParams: ResultSubParam[] = [
      {
        title: "BNP_score",
        value: convertResult["BNP_score"],
      },
      {
        title: "GOKAN_score",
        value: convertResult["GOKAN_score"],
      },
      {
        title: "NYHA_prob",
        value: convertResult["NYHA_prob"],
        color: convertResult["NYHA_prob_PN"] === "emphasize" ? "red" : "black",
      },
      {
        title: "CPA_prob",
        value: convertResult["CPA_prob"],
        color: convertResult["CPA_prob_PN"] === "emphasize" ? "red" : "black",
      },
      {
        title: "CON_prob",
        value: convertResult["CON_prob"],
        color: convertResult["CON_prob_PN"] === "emphasize" ? "red" : "black",
      },
      {
        title: "LVP_DR",
        value: convertResult["LVP_DR"],
      },
      {
        title: "LVP_RM",
        value: convertResult["LVP_RM"],
      },
      {
        title: "DSTP_DR",
        value: convertResult["DSTP_DR"],
      },
      {
        title: "DSTP_PA",
        value: convertResult["DSTP_PA"],
      },
    ];

    return {
      mainParam,
      subParams,
      supplementaryInfo: [],
      diagramIndex: [],
      diagramParams: [],
      phrases,
    };
  }

  async renderResult(
    key: number,
    fileId: string,
    t: TFunction
  ): Promise<ReactElement> {
    const result = await this.getResult(fileId);
    const dashboardData = result
      ? Br1Engine.convertToDashboardData(result)
      : ERROR_DASHBOARD_DATA;
    return (
      <AnalysisResultCardLayout title={t("Result.Br1_analysis_result")}>
        <Box>
          <Center mt={4} mb={2}>
            {t("Result.Heart_Failure_result")}
          </Center>
          <Flex
            gap={{ base: 4, md: 10 }}
            mt={2}
            mb={4}
            justifyContent="center"
            alignItems="center"
          >
            <MainParamBox
              mainParam={{
                title: "Result.BNP_Score",
                subtitle: "Result.BNP_Score_remarks",
                value: dashboardData.subParams[0].value,
                border: "solid 3px #F9D9D2",
                maxWidth: "200px",
              }}
            />
            <MainParamBox
              mainParam={{
                title: "Result.GOKAN_Score",
                subtitle: "Result.GOKAN_Score_remarks",
                value: dashboardData.subParams[1].value,
                border: "solid 3px #F9D9D2",
                maxWidth: "200px",
              }}
            />
          </Flex>
          <Center mt={4} mb={2}>
            {t("Result.Br1_Risk_Score")}
          </Center>
          <Flex
            gap={2}
            mt={2}
            mb={4}
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
          >
            <SubParamBox
              title={t("Result.HFSV_Index")}
              subtitle={t("Result.HFSV_Index_remarks")}
              value={dashboardData.subParams[2]?.value || ""}
              range="[ 0 - 100 ]"
              color={dashboardData.subParams[2]?.color}
            />
            <SubParamBox
              title={t("Result.EV_Index")}
              subtitle={t("Result.EV_Index_remarks")}
              value={dashboardData.subParams[3]?.value || ""}
              range="[ 0 - 100 ]"
              color={dashboardData.subParams[3]?.color}
            />
            <SubParamBox
              title={t("Result.CV_Index")}
              subtitle={t("Result.CV_Index_remarks")}
              value={dashboardData.subParams[4]?.value || ""}
              range="[ 0 - 100 ]"
              color={dashboardData.subParams[4]?.color}
            />
          </Flex>
          <Center mt={4} mb={2}>
            {t("Result.Voice_Analysis")}
          </Center>
          <Flex
            gap={4}
            mt={2}
            mb={4}
            justifyContent="center"
            alignItems="center"
          >
            <PhraseBox
              phrase={t(
                dashboardData.phrases?.[0]
                  ? t("Result.VoiceStability.Title")
                  : ""
              )}
              length={dashboardData.subParams[5]?.value ?? ""}
              strength={dashboardData.subParams[6]?.value ?? ""}
            />
            <PhraseBox
              phrase={t(
                dashboardData.phrases?.[1] ? t("Result.VoiceRhythm.Title") : ""
              )}
              length={dashboardData.subParams[7]?.value ?? ""}
              strength={dashboardData.subParams[8]?.value ?? ""}
            />
          </Flex>
        </Box>
      </AnalysisResultCardLayout>
    );
  }
}
