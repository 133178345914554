import { ReactElement } from "react";
import { Link } from "react-router-dom";
import { Button, Box, Text, Icon } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { FaChevronCircleLeft } from "react-icons/fa";

export function BackToHomeButton(): ReactElement {
  const { i18n } = useTranslation();
  return (
    <Link to={`/${i18n.language}/home`}>
      <Box
        position="absolute"
        top="50%"
        transform="translateY(-50%)"
        left="0"
        width="auto"
        display="flex"
      >
        <Button variant="btn_secondary" h="25px" w="75px">
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            width="100%"
            position="relative"
          >
            <Icon
              as={FaChevronCircleLeft}
              boxSize={3.5}
              position="absolute"
              left="50%"
              transform="translateX(-30px)"
            />
            <Text ml="15px" fontSize="13px">
              Home
            </Text>
          </Box>
        </Button>
      </Box>
    </Link>
  );
}
