import { Navigate, Outlet, useParams } from "react-router-dom";
import { ReactElement } from "react";
import { AnalysisEngineType, isValidAnalysisEngineType } from "../../../types";
import { ENABLED_ENGINES } from "../../../environments";

function isAllowedEngineType(type: AnalysisEngineType): boolean {
  return ENABLED_ENGINES.includes(type);
}

export function EngineType(): ReactElement {
  const { engineType } = useParams();

  if (
    isValidAnalysisEngineType(engineType) &&
    isAllowedEngineType(engineType)
  ) {
    return <Outlet />;
  } else {
    return <Navigate to="/404" replace />;
  }
}
