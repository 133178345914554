import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
} from "@chakra-ui/react";
import { ReactElement, useRef } from "react";

type Props = {
  isOpen: boolean;
  onOk: () => void;
  onClose: () => void;
  ok: string;
  cancel: string;
  title?: string;
  description?: string;
};

export function SimpleAlertDialog(props: Props): ReactElement {
  const cancelRef = useRef<HTMLButtonElement | null>(null);

  return (
    <AlertDialog
      isOpen={props.isOpen}
      leastDestructiveRef={cancelRef}
      onClose={props.onClose}
    >
      <AlertDialogOverlay>
        <AlertDialogContent mx={3}>
          {props.title && (
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              {props.title}
            </AlertDialogHeader>
          )}

          {props.description && (
            <AlertDialogBody>{props.description}</AlertDialogBody>
          )}

          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={props.onClose}>
              {props.cancel}
            </Button>
            <Button colorScheme="red" onClick={props.onOk} ml={2}>
              {props.ok}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
}
