import { ReactElement } from "react";
import {
  Container,
  ContainerProps,
  Flex,
  Heading,
  HeadingProps,
  VStack,
} from "@chakra-ui/react";
import { BackToHomeButton } from "./BackToHomeButton";
import { BackButton } from "./BackButton";

export function Layout(props: ContainerProps): ReactElement {
  return (
    <Container
      maxW="2xl"
      paddingX={{ base: 5, md: 10 }}
      paddingY={{ base: 3, md: 6 }}
      {...props}
    />
  );
}

type TitleProps = HeadingProps & {
  subTitle?: ReactElement;
  showBackToHomeButton?: boolean;
  showBackButton?: boolean;
};

function Title(props: TitleProps): ReactElement {
  const { subTitle, showBackToHomeButton, showBackButton, ...headingProps } =
    props;
  if (subTitle) {
    return (
      <VStack
        spacing={0}
        height="64px"
        justify="center"
        marginTop={{ base: -3, md: -6 }}
      >
        <Heading
          as="h1"
          fontSize="22px"
          display="flex"
          justifyContent="center"
          alignItems="center"
          {...headingProps}
        />
        {subTitle}
      </VStack>
    );
  } else {
    return (
      <Flex
        alignItems="center"
        justifyContent="center"
        marginTop={{ base: -3, md: -6 }}
        position="relative"
        width="full"
      >
        {showBackToHomeButton && <BackToHomeButton />}
        {showBackButton && <BackButton />}
        <Heading
          as="h1"
          width="full"
          height="64px"
          fontSize="22px"
          display="flex"
          justifyContent="center"
          alignItems="center"
          {...headingProps}
        />
      </Flex>
    );
  }
}

Layout.Title = Title;
